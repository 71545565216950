import { DropdownMenuTriggerProps } from "@kobalte/core/dropdown-menu";
import { ct } from "@mooovex/components";
import { Button } from "@mooovex/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "@mooovex/components/ui/dropdown-menu";
import { availableTransportcompanies, fetchTransportcompanies } from "@mooovex/supabase-client";
import { Component, createEffect, createSignal, For, Show } from "solid-js";
import { selectedTransportcompany, setSelectedTransportcompanyId } from "../services/state/transportcompanies.state";

const TransportcompanySelectorC: Component = () => {
  const [isOpen, setIsOpen] = createSignal(false);

  createEffect(() => {
    if (isOpen()) {
      fetchTransportcompanies();
    }
  });

  return (
    <>
      <DropdownMenu open={isOpen()} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger
          as={(triggerProps: DropdownMenuTriggerProps) => (
            <Button {...triggerProps} class="tw-w-full tw-truncate" variant="outline" size="sm">
              {selectedTransportcompany()?.name ?? ct.vehicleSelection.noCompany()}
            </Button>
          )}
        />
        <DropdownMenuContent>
          <DropdownMenuRadioGroup
            value={selectedTransportcompany()?.id as unknown as string}
            onChange={(value) => setSelectedTransportcompanyId(value as unknown as number | undefined)}
          >
            <Show when={availableTransportcompanies().length}>
              <DropdownMenuRadioItem value={undefined as unknown as string}>
                {ct.vehicleSelection.noCompany()}
              </DropdownMenuRadioItem>
            </Show>
            <For
              each={availableTransportcompanies()}
              fallback={<DropdownMenuItem>{ct.vehicleSelection.noCompanies()}</DropdownMenuItem>}
            >
              {(transportcompany) => (
                <DropdownMenuRadioItem value={transportcompany.id as unknown as string}>
                  {transportcompany.name}
                </DropdownMenuRadioItem>
              )}
            </For>
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

export default TransportcompanySelectorC;
