import { Tables } from "@mooovex/supabase";
import { ReactiveMap } from "@solid-primitives/map";
import { createSelector, createSignal } from "solid-js";
import { supabase, userSignal } from ".";

export const transportcompaniesCache = new ReactiveMap<number, Tables<"transportcompanies">>();

export async function fetchTransportcompanies() {
  const { data, error } = await supabase.from("transportcompanies").select("*").order("name");
  if (error) throw error;

  for (const company of data) {
    transportcompaniesCache.set(company.id, company);
  }

  setAvailableTransportcompanyIds(data.map((tc) => tc.id));

  return data;
}

export async function fetchCurrentTransportcompany() {
  const user = userSignal();
  if (!user) throw new Error("User not logged in");
  const { data, error } = await supabase
    .from("drivers")
    .select("current_transportcompany:transportcompanies(*)")
    .eq("staff_id", user.id)
    .single();
  if (error) throw error;

  if (data.current_transportcompany) {
    transportcompaniesCache.set(data.current_transportcompany.id, data.current_transportcompany);
  }

  setCurrentTransportcompanyId(data.current_transportcompany?.id);

  return data.current_transportcompany;
}

export const [availableTransportcompanyIds, setAvailableTransportcompanyIds] = createSignal<number[]>([]);
export const [currentTransportcompanyId, setCurrentTransportcompanyId] = createSignal<number>();

export const availableTransportcompanies = () =>
  availableTransportcompanyIds().flatMap((id) =>
    transportcompaniesCache.has(id) ? [transportcompaniesCache.get(id)!] : []
  );

export const currentTransportcompany = () => transportcompaniesCache.get(currentTransportcompanyId()!);
export const isCurrentTransportcompany = createSelector(currentTransportcompany, (a, b) => !!a && !!b && a.id === b.id);
