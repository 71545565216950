import { DropdownMenuSubTriggerProps } from "@kobalte/core/dropdown-menu";
import { bearerClient } from "@mooovex/api-client";
import { PlaceAdapter } from "@mooovex/api-schema";
import { ct, currentLocale } from "@mooovex/components";
import { Button } from "@mooovex/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuGroupLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@mooovex/components/ui/dropdown-menu";
import { makePersisted } from "@solid-primitives/storage";
import { createQuery } from "@tanstack/solid-query";
import ArrowUpDownIcon from "lucide-solid/icons/arrow-up-down";
import RotateCcwIcon from "lucide-solid/icons/rotate-ccw";
import { For, Show, Suspense } from "solid-js";
import { createStore } from "solid-js/store";
import { selectedTransportcompanyId } from "../services/state/transportcompanies.state";
import { NavbarActions } from "./Navbar.c";

function formatPlaces(places: PlaceAdapter.Place[]) {
  const start = places[0];
  const end = places[places.length - 1];

  if (!start.city || !end.city) {
    return `${start.name} - ${end.name}`;
  }

  return start.city === end.city ? ct.invoicing.local(start.city) : `${start.city} - ${end.city}`;
}

export function Invoicing() {
  const [order, setOrder] = makePersisted(
    createStore<Record<"months" | "invoiceRecipients" | "transfers", "asc" | "desc">>({
      months: "desc",
      invoiceRecipients: "asc",
      transfers: "asc",
    }),
    { name: "mooovex:invoicingOrder" }
  );

  const invoicesQuery = createQuery(() => ({
    queryKey: ["invoices", order],
    queryFn: async () => {
      const response = await bearerClient.v2.transfers.invoices.$get({
        query: {
          asTransportcompanyId: selectedTransportcompanyId()!.toString(),
          monthsOrder: order.months,
          invoiceRecipientsOrder: order.invoiceRecipients,
          transfersOrder: order.transfers,
        },
      });
      if (response.status !== 200) {
        throw new Error("Failed to fetch invoices", { cause: response });
      }
      return await response.json();
    },
    staleTime: 60000,
    select: (data) => data.months,
  }));

  return (
    <>
      <NavbarActions>
        <DropdownMenu>
          <DropdownMenuTrigger
            as={(props: DropdownMenuSubTriggerProps) => (
              <Button size="icon" variant="outline" {...props}>
                <ArrowUpDownIcon />
              </Button>
            )}
          />
          <DropdownMenuContent>
            <DropdownMenuGroup>
              <DropdownMenuGroupLabel>{ct.invoicing.months()}</DropdownMenuGroupLabel>
              <DropdownMenuRadioGroup
                value={order.months}
                onChange={(value) => setOrder("months", value as "asc" | "desc")}
              >
                <DropdownMenuRadioItem value="asc">{ct.order.oldFirst()}</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="desc">{ct.order.newFirst()}</DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuGroupLabel>{ct.invoicing.invoiceRecipients()}</DropdownMenuGroupLabel>
              <DropdownMenuRadioGroup
                value={order.invoiceRecipients}
                onChange={(value) => setOrder("invoiceRecipients", value as "asc" | "desc")}
              >
                <DropdownMenuRadioItem value="asc">{ct.order.aToZ()}</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="desc">{ct.order.zToA()}</DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuGroupLabel>{ct.invoicing.transfers()}</DropdownMenuGroupLabel>
              <DropdownMenuRadioGroup
                value={order.transfers}
                onChange={(value) => setOrder("transfers", value as "asc" | "desc")}
              >
                <DropdownMenuRadioItem value="asc">{ct.order.oldFirst()}</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="desc">{ct.order.newFirst()}</DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <Button size="icon" variant="outline" onclick={() => invoicesQuery.refetch()}>
          <RotateCcwIcon />
        </Button>
      </NavbarActions>
      <div class="tw-h-full tw-grow tw-overflow-auto">
        <Suspense
          fallback={<div class="tw-flex tw-h-full tw-items-center tw-justify-center">{ct.common.loading()}...</div>}
        >
          <Show
            when={invoicesQuery.data?.length}
            fallback={<div class="tw-flex tw-h-full tw-items-center tw-justify-center">{ct.common.noResults()}</div>}
          >
            <div class="tw-grid tw-grid-cols-[auto_minmax(0,1fr)_auto_auto] tw-items-center tw-gap-x-2 tw-overflow-clip tw-text-nowrap tw-px-1">
              <For each={invoicesQuery.data}>
                {(month, i) => (
                  <>
                    <div
                      class="tw-sticky tw-top-0 tw-col-span-full -tw-mx-1 tw-flex tw-h-12 tw-items-center tw-justify-center tw-bg-neutral-300 tw-px-1 tw-text-lg tw-font-bold"
                      classList={{ "tw-mt-12": i() !== 0 }}
                    >
                      {Intl.DateTimeFormat(currentLocale(), { month: "long", year: "numeric" }).format(
                        new Date(month.month)
                      )}
                    </div>
                    <For each={month.invoiceRecipients}>
                      {(invoiceRecipient, i) => (
                        <>
                          <div
                            class="tw-sticky tw-top-12 tw-col-span-full -tw-mx-1 tw-flex tw-h-12 tw-items-center tw-truncate tw-bg-neutral-100 tw-px-1 tw-text-lg"
                            classList={{ "tw-mt-6": i() !== 0 }}
                          >
                            {invoiceRecipient.invoiceTo}
                          </div>
                          <For each={invoiceRecipient.transfers}>
                            {(transfer) => {
                              return (
                                <>
                                  <div class="tw-flex tw-h-12 tw-items-center">
                                    {Intl.DateTimeFormat(currentLocale(), { day: "2-digit" }).format(
                                      new Date(transfer.createdAt)
                                    )}
                                  </div>
                                  <div class="tw-flex tw-h-12 tw-items-center tw-overflow-x-auto">
                                    {formatPlaces(transfer.waypoints)}
                                  </div>
                                  <div class="tw-flex tw-h-12 tw-items-center">{transfer.pax}</div>
                                  <div class="tw-flex tw-h-12 tw-items-center tw-justify-end">
                                    {ct.formatters.euros(transfer.price)}
                                  </div>
                                </>
                              );
                            }}
                          </For>
                          <Show when={month.invoiceRecipients.length > 1}>
                            <div class="tw-col-span-full -tw-mx-1 tw-flex tw-h-12 tw-items-center tw-justify-end tw-bg-neutral-100 tw-p-1 tw-font-bold tw-shadow">
                              {ct.invoicing.recipientsMonthlyTotal()}: {ct.formatters.euros(invoiceRecipient.amount)}
                            </div>
                          </Show>
                        </>
                      )}
                    </For>
                    <div class="tw-col-span-full -tw-mx-1 tw-flex tw-h-12 tw-items-center tw-justify-end tw-bg-neutral-300 tw-p-1 tw-font-bold tw-shadow">
                      {ct.invoicing.monthlyTotal()}: {ct.formatters.euros(month.amount)}
                    </div>
                  </>
                )}
              </For>
            </div>
          </Show>
        </Suspense>
      </div>
    </>
  );
}
