import * as API from "@mooovex/api-schema";
import { makePersisted } from "@solid-primitives/storage";
import { createSignal } from "solid-js";

export const [origins, setOrigins] = makePersisted(createSignal<Array<API.google_place_details.ResponseBody>>([]), {
  name: "mooovex:pricelist.origins",
});

export const [destinations, setDestinations] = makePersisted(
  createSignal<Array<API.google_place_details.ResponseBody>>([]),
  {
    name: "mooovex:pricelist.destinations",
  }
);

export const [routes, setRoutes] = makePersisted(
  createSignal<
    Array<{
      originId: string | number;
      destinationId: string | number;
      routeId: string;
      price: { full: number; person: number } | undefined;
    }>
  >([]),
  {
    name: "mooovex:pricelist.routes",
  }
);

export async function addOrigin(input: API.google_place_details.ResponseBody) {
  setOrigins([...origins(), input]);
}

export async function addDestination(input: API.google_place_details.ResponseBody) {
  setDestinations([...destinations(), input]);
}

export function removeOrigin(id: string | number) {
  removeRoute({ originId: id });
  setOrigins(origins().filter((place) => API.PlaceAdapter.getId(place) !== id));
}

export function removeDestination(id: string | number) {
  removeRoute({ destinationId: id });
  setDestinations(destinations().filter((place) => API.PlaceAdapter.getId(place) !== id));
}

export function setRoute(origin_id: string | number, destination_id: string | number, routeId: string) {
  removeRoute({ originId: origin_id, destinationId: destination_id });
  setRoutes([
    ...routes(),
    {
      originId: origin_id,
      destinationId: destination_id,
      routeId: routeId,
      price: undefined,
    },
  ]);
}

export function removeRoute({
  originId,
  destinationId,
}: {
  originId?: string | number;
  destinationId?: string | number;
}) {
  setRoutes(
    routes().filter(
      (route) =>
        !(
          (originId || destinationId) &&
          (!originId || route.originId === originId) &&
          (!destinationId || route.destinationId === destinationId)
        )
    )
  );
}

export function setPrice(
  originId: string | number,
  destinationId: string | number,
  price: number,
  additionalPersonPrice: number
) {
  const route = routes().find((route) => route.originId === originId && route.destinationId === destinationId);
  if (route) {
    route.price = {
      full: price,
      person: additionalPersonPrice,
    };
    removeRoute({ originId, destinationId });
    setRoutes([...routes(), route]);
  }
}
