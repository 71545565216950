import { ChangePasswordForm, DeleteAccountForm, createModal, ct } from "@mooovex/components";
import { changePassword, myStaff, userSignal } from "@mooovex/supabase-client";
import { A, useSearchParams } from "@solidjs/router";
import { Component, createEffect } from "solid-js";
import { Portal } from "solid-js/web";

const AccountPage: Component = () => {
  const [DeleteAccountModal, { show: showDeleteAccountModal }] = createModal();
  const [ChangePasswordModal, { show: showChangePasswordModal, hide: hideChangePasswordModal }] = createModal();
  const [searchParams] = useSearchParams<{ changePassword: string; deleteAccount: string }>();

  createEffect(() => {
    if (searchParams.changePassword) {
      showChangePasswordModal();
    }

    if (searchParams.deleteAccount) {
      showDeleteAccountModal();
    }
  });

  return (
    <>
      <div class="p-2 d-flex flex-column gap-2 mx-auto" style={{ "max-width": "500px" }}>
        <A class="btn btn-light align-self-start" href="/service">
          <i class="bi-arrow-left me-2" />
          {ct.common.back()}
        </A>
        <h1>{ct.account._()}</h1>
        <table class="table">
          <tbody>
            <tr>
              <th>{ct.account.name()}</th>
              <td>{myStaff()?.first_name}</td>
            </tr>
            <tr>
              <th>{ct.account.surname()}</th>
              <td>{myStaff()?.last_name}</td>
            </tr>
            <tr>
              <th>{ct.account.email._()}</th>
              <td>{userSignal()?.email}</td>
            </tr>
            <tr>
              <th>{ct.account.phoneNumber()}</th>
              <td>{myStaff()?.phone}</td>
            </tr>
          </tbody>
        </table>
        <button class="btn btn-border-primary" onclick={() => showChangePasswordModal()}>
          {ct.account.changePassword()}
        </button>
        <button class="btn btn-border-danger" onclick={() => showDeleteAccountModal()}>
          {ct.account.delete._()}
        </button>
      </div>
      <Portal>
        <DeleteAccountModal align="center">
          <div class="modal-header">
            <h5 class="modal-title">{ct.account.delete._()}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" />
          </div>
          <div class="modal-body">
            <DeleteAccountForm />
          </div>
        </DeleteAccountModal>
        <ChangePasswordModal align="center">
          <div class="modal-header">
            <h5 class="modal-title">{ct.account.changePassword()}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" />
          </div>
          <div class="modal-body">
            <ChangePasswordForm
              onSubmit={async (password) => {
                await changePassword(password);
                hideChangePasswordModal();
                alert(ct.validation.passwordChange.success());
              }}
            />
          </div>
        </ChangePasswordModal>
      </Portal>
    </>
  );
};

export default AccountPage;
