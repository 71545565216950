import { SolidMapMarker, useSolidMap } from "@mooovex/solid-map";
import { currentTransportcompanyId } from "@mooovex/supabase-client";
import { cva } from "class-variance-authority";
import { Marker } from "maplibre-gl";
import { createEffect, onCleanup, ParentComponent, Show } from "solid-js";
import { cn } from "../cn";
import { Driverlocation } from "../DriverlocationsProvider";
import { IdentifierBadge } from "../vehicles/IdentifierBadge";
import { MapDriverMarkerPopup } from "./MapDriverMarkerPopup";
type Props = {
  driverlocation: Driverlocation;
  follow?: boolean;
  size?: "sm" | "default" | "lg";
};

const arrowVariants = cva(
  "tw-absolute tw-left-1/2 tw-top-[-9px] tw-size-0 -tw-translate-x-1/2 tw-border-b-[10px] tw-border-l-[5px] tw-border-r-[5px] tw-border-t-0 tw-border-solid tw-border-transparent",
  {
    variants: {
      status: {
        available: "tw-border-b-status-available",
        empty: "tw-border-b-status-empty",
        occupied: "tw-border-b-status-occupied",
        invisible: "tw-border-b-status-invisible",
        outofservice: "tw-border-b-status-outofservice",
      },
    },
    defaultVariants: {
      status: "outofservice",
    },
  }
);

const headingVariants = cva("tw-absolute tw-origin-center", {
  variants: {
    size: {
      sm: "-tw-left-3 -tw-top-3 tw-size-6",
      default: "-tw-left-3.5 -tw-top-3.5 tw-size-7",
      lg: "-tw-left-4 -tw-top-4 tw-size-8",
    },
  },
  defaultVariants: {
    size: "default",
  },
});

const markerVariants = cva("tw-cursor-pointer hover:tw-z-[40]", {
  variants: {
    status: {
      available: null,
      empty: null,
      occupied: null,
      invisible: "tw-hidden",
      outofservice: "tw-hidden",
    },
    relationship: {
      own: null,
      partner: null,
      regular: null,
    },
  },
  defaultVariants: {
    status: "outofservice",
    relationship: "regular",
  },
  compoundVariants: [
    {
      status: "available",
      relationship: "own",
      class: "tw-z-[30]",
    },
    {
      status: "occupied",
      relationship: "own",
      class: "tw-z-[29]",
    },
    {
      status: "empty",
      relationship: "own",
      class: "tw-z-[28]",
    },
    {
      status: "available",
      relationship: "partner",
      class: "tw-z-[27]",
    },
    {
      status: "available",
      relationship: "regular",
      class: "tw-z-[26]",
    },
    {
      status: "occupied",
      relationship: "partner",
      class: "tw-z-[25]",
    },
    {
      status: "occupied",
      relationship: "regular",
      class: "tw-z-[24]",
    },
    {
      status: "empty",
      relationship: "partner",
      class: "tw-z-[23]",
    },
    {
      status: "empty",
      relationship: "regular",
      class: "tw-z-[22]",
    },
  ],
});

export const driverMarkerInstances = new Map<string, Marker>();

export const MapDriverMarker: ParentComponent<Props> = (props) => {
  const map = useSolidMap();

  createEffect(() => {
    if (props.follow) {
      const zoomlevel = calculateZoomLevel(
        props.driverlocation.speed * 30,
        props.driverlocation.point[1],
        map.getContainer().clientWidth
      );
      map.flyTo({ center: props.driverlocation.point, zoom: Math.min(zoomlevel, 18) });
    }
  });

  onCleanup(() => {
    driverMarkerInstances.delete(props.driverlocation.driverId);
  });

  return (
    <SolidMapMarker
      lngLat={props.driverlocation.point}
      onInit={(marker) => {
        driverMarkerInstances.set(props.driverlocation.driverId, marker);
      }}
      popupOptions={{
        closeButton: false,
        closeOnMove: false,
        closeOnClick: true,
        anchor: "bottom",
        focusAfterOpen: false,
        offset: [0, props.size === "sm" ? -12 : props.size === "lg" ? -16 : -14],
        maxWidth: "300px",
        className: "tw-z-[40]",
      }}
      class={cn(
        markerVariants({
          status: props.driverlocation.status,
          relationship:
            props.driverlocation.transportcompanyId === currentTransportcompanyId()
              ? "own"
              : props.driverlocation.transportcompanyPartnerIndex !== null
                ? "partner"
                : "regular",
        })
      )}
      popupElement={<MapDriverMarkerPopup driverlocation={props.driverlocation} />}
    >
      <Show
        when={
          typeof props.driverlocation.heading === "number" &&
          props.driverlocation.heading !== -1 &&
          props.driverlocation.speed * 3.6 >= 10
        }
      >
        <div
          class={cn(headingVariants({ size: props.size }))}
          style={{
            transform: `rotate(${props.driverlocation.heading}deg)`,
          }}
        >
          <div class={cn(arrowVariants({ status: props.driverlocation.status }))} />
        </div>
      </Show>
      <IdentifierBadge
        size={props.size}
        class="tw-absolute -tw-translate-x-1/2 -tw-translate-y-1/2 tw-shadow-[0_0_5px_rgba(255,255,255,0.3)]"
        status={props.driverlocation.status}
      >
        {props.driverlocation.vehicleIdentifier}
      </IdentifierBadge>
    </SolidMapMarker>
  );
};

function calculateZoomLevel(widthMeters: number, latitude: number, mapWidth: number): number {
  // Constants
  const earthCircumference = 40075016.686; // Equatorial circumference of the Earth in meters
  const tileSize = 512; // Tile size in pixels

  // Calculate the zoom level
  const zoomLevel = Math.log2(
    (earthCircumference * Math.cos(latitude * (Math.PI / 180))) / ((widthMeters / mapWidth) * tileSize)
  );

  return zoomLevel;
}
