import { useTransferForm } from "@mooovex/components";
import { Button } from "@mooovex/components/ui/button";
import PanelBottomCloseIcon from "lucide-solid/icons/panel-bottom-close";
import PanelBottomOpenIcon from "lucide-solid/icons/panel-bottom-open";
import { Component } from "solid-js";
import { Dynamic } from "solid-js/web";

type Props = {};

export const MapHideFormControl: Component<Props> = (props) => {
  const [transferForm, setTransferForm] = useTransferForm();

  return (
    <>
      <Button
        variant="outline"
        size="icon"
        type="button"
        onclick={() => setTransferForm("formVisible", (prev) => !prev)}
      >
        <Dynamic component={transferForm.formVisible ? PanelBottomCloseIcon : PanelBottomOpenIcon} />
      </Button>
    </>
  );
};
