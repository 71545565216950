import { MyCoordinates2D } from "./MyCoordinates2D.class";

export interface Clonable<T> {
  clone(): T;
}

export interface Serializable<T extends JSONValue> {
  toJSON(): T;
}

export type JSONValue = string | number | boolean | JSONObject | Array<JSONValue> | null;
export type JSONObject = {
  [key: string]: JSONValue;
};

export interface Comparable {
  valueOf(): number;
}

export const uri = (strings: TemplateStringsArray, ...values: string[]) =>
  values.reduce((result, value, i) => result + strings[i] + encodeURIComponent(value), "");

export function makeUnknownPlace(coordinates: MyCoordinates2D) {
  return {
    google_place_id: "",
    name: coordinates.toLatLngString(5),
    formatted_address: "",
    location: coordinates.toGeoJSON(),
    types: [],
  };
}

export function nullishToJSON<T extends JSONValue, S extends Serializable<T> | null | undefined>(
  input: S
): S extends Serializable<T> ? T : S extends null ? null : S extends undefined ? undefined : T | null | undefined {
  if (input === null) {
    return null as any;
  } else if (input === undefined) {
    return undefined as any;
  } else {
    return input.toJSON() as any;
  }
}

export function fromNullish<T, U, V extends U | null | undefined>(
  inputJson: V,
  InputClass: { fromJSON: (json: U) => T }
): U extends V ? T : U extends null ? null : U extends undefined ? undefined : T | null | undefined {
  if (inputJson === null) {
    return null as any;
  }
  if (inputJson === undefined) {
    return undefined as any;
  }
  return InputClass.fromJSON(inputJson) as any;
}

/**
 * Interpolate between two colors based on a given ratio.
 * @param color1 the first color
 * @param color2 the second color
 * @param ratio the ratio between the two colors
 * @returns the interpolated color
 */
export function interpolateColors(
  color1: [number, number, number],
  color2: [number, number, number],
  ratio: number
): [number, number, number] {
  // Ensure the ratio is within the range [0, 1]
  ratio = Math.max(0, Math.min(1, ratio));
  const r = Math.round(color1[0] + ratio * (color2[0] - color1[0]));
  const g = Math.round(color1[1] + ratio * (color2[1] - color1[1]));
  const b = Math.round(color1[2] + ratio * (color2[2] - color1[2]));
  return [r, g, b];
}

/**
 * Splits an array into smaller chunks of the specified size.
 * The last chunk may contain fewer elements if the array length is not evenly divisible by the chunk size.
 * @param arr The array to split into chunks
 * @param chunkSize The maximum size of each chunk
 * @returns An array containing the chunks
 */
export function chunkArray<T>(arr: T[], chunkSize: number): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
}

export const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

export async function catchError<T, E = Error>(promise: Promise<T>): Promise<[undefined, T] | [E]> {
  return promise.then((result) => [undefined, result] satisfies [undefined, T]).catch((error) => [error]);
}
