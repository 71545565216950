import { ct, currentLocale } from "@mooovex/components";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@mooovex/components/ui/card";

export function MooovexDriver() {
  return (
    <>
      <div class="tw-m-auto tw-p-2">
        <Card>
          <CardHeader>
            <CardTitle>Mooovex Driver</CardTitle>
            <CardDescription>{ct.mooovexDriver.appRequired()}</CardDescription>
          </CardHeader>
          <CardContent>
            <a href="https://apps.apple.com/it/app/mooovex-driver/id6463634602">
              <img
                src={`/downloadAppBadges/ios_${currentLocale()}_dark.svg`}
                class="tw-m-auto tw-w-full tw-max-w-[270px]"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.mooovex.apps.driver">
              <img src={`/downloadAppBadges/play_${currentLocale()}.png`} class="tw-m-auto tw-mt-2" />
            </a>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
