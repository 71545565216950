import { addAuthListener, removeAuthListener } from "@mooovex/supabase-client";
import { A, useNavigate } from "@solidjs/router";
import { Component, onCleanup, onMount } from "solid-js";

const ResetPasswordCallbackC: Component = () => {
  const navigate = useNavigate();
  const handler = () => navigate("/user?changePassword=true");
  onMount(() => addAuthListener("PASSWORD_RECOVERY", handler));
  onCleanup(() => removeAuthListener("PASSWORD_RECOVERY", handler));
  return (
    <>
      <h1>If you see this, something went wrong!</h1>
      <A href="/login">Back to login</A>
    </>
  );
};

export default ResetPasswordCallbackC;
