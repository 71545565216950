import { SolidMap } from "@mooovex/solid-map";
import { JSX, ParentComponent, splitProps } from "solid-js";

type Props = JSX.HTMLAttributes<HTMLDivElement> & {
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
};

export const MapComponent: ParentComponent<Props> = (props) => {
  const [parentProps, myProps, divProps] = splitProps(
    props,
    ["children"],
    ["paddingTop", "paddingBottom", "paddingLeft", "paddingRight"]
  );

  return (
    <>
      <SolidMap
        {...divProps}
        mapOptions={{
          style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${import.meta.env.VITE_MAPTILER_API_KEY}`,
          attributionControl: false,
          center: [11, 46],
          zoom: 7,
        }}
        onInit={(map) => {
          map.touchZoomRotate.disableRotation();
          map.touchPitch.disable();
        }}
      >
        {parentProps.children}
      </SolidMap>
    </>
  );
};
