import { ResetPasswordForm } from "@mooovex/components";
import { sendPasswordResetEmail } from "@mooovex/supabase-client";
import { useSearchParams } from "@solidjs/router";
import { Component } from "solid-js";

const ResetPasswordPage: Component = () => {
  const [searchParams] = useSearchParams<{ email?: string }>();

  return (
    <ResetPasswordForm
      onSubmit={async ({ email }) => {
        await sendPasswordResetEmail(
          email,
          `${import.meta.env.VITE_FRONTEND_URL}/resetPassword/otp?email=${encodeURIComponent(email)}`
        );
      }}
      loginHref="/login"
      email={searchParams.email}
    />
  );
};

export default ResetPasswordPage;
