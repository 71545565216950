import XIcon from "lucide-solid/icons/x";
import { Component, Index, createSelector } from "solid-js";
import { useTransferForm } from "../TransferFormProvider";
import { Button } from "../ui/button";

type Props = {
  max: number;
  min: number;
};

export const PassengerCountSelectorOverlay: Component<Props> = (props) => {
  const [transferForm, setTransferForm] = useTransferForm();
  const isSelected = createSelector(() => transferForm.form.passengerCount);

  return (
    <div
      class="tw-absolute tw-inset-0 tw-z-[4] tw-translate-y-full tw-overflow-hidden tw-bg-white tw-p-1.5 tw-transition-transform"
      classList={{
        "tw-translate-y-0": transferForm.passengerCountVisible,
      }}
    >
      <div class="tw-flex tw-h-full tw-items-center tw-gap-1.5">
        <div class="tw-h-full tw-grow tw-overflow-y-auto">
          <div class="tw-grid tw-min-h-full tw-auto-rows-[64px] tw-grid-cols-[repeat(auto-fill,64px)] tw-content-center tw-items-center tw-justify-center tw-gap-1.5">
            <Index each={new Array(props.max - props.min + 1)}>
              {(_, i) => {
                return (
                  <>
                    <Button
                      variant={isSelected(i + props.min) ? "default" : "outline"}
                      size="icon"
                      class="!tw-size-full tw-select-none !tw-text-3xl !tw-font-bold !tw-leading-none"
                      onclick={() => {
                        setTransferForm("form", "passengerCount", i + props.min);
                        setTransferForm("passengerCountVisible", false);
                      }}
                    >
                      {i + props.min}
                    </Button>
                  </>
                );
              }}
            </Index>
          </div>
        </div>
        <Button
          size="icon"
          variant="outline"
          class="tw-shrink-0 tw-self-start"
          onclick={() => {
            setTransferForm("form", "passengerCount", undefined);
            setTransferForm("passengerCountVisible", false);
          }}
        >
          <XIcon />
        </Button>
      </div>
    </div>
  );
};
