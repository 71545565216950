import * as API from "@mooovex/api-schema";
import { SolidMapLayer, SolidMapSource } from "@mooovex/solid-map";
import { Component } from "solid-js";

type Props = {
  linestring: API.Shared.GeoJson.LineString;
  style?: "default" | "return_route";
};

export const MapLineString: Component<Props> = (props) => {
  return (
    <SolidMapSource
      sourceSpecification={{
        type: "geojson",
        data: props.linestring,
        lineMetrics: props.style !== "return_route",
      }}
    >
      <SolidMapLayer
        layerSpecification={{
          type: "line",
          paint:
            props.style === "return_route"
              ? {
                  "line-color": "red",
                  "line-width": 3,
                  "line-dasharray": [3, 2],
                }
              : {
                  "line-width": 4,
                  "line-gradient": ["interpolate", ["linear"], ["line-progress"], 0, "#2b71b7", 1, "#23bb4c"],
                },
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
        }}
      />
    </SolidMapSource>
  );
};
