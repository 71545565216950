import { Tables } from "@mooovex/supabase";
import { createSelector, createSignal } from "solid-js";
import {
  currentTransportcompanyId,
  currentVehicleId,
  myDevice,
  setCurrentTransportcompanyId,
  setCurrentVehicleId,
  supabase,
  userSignal,
} from ".";

type UpdateDriverPayload = {
  vehicle_id?: number | null;
  driver_status?: Driverstatus;
  transportcompany_id?: number | null;
};

export async function update_driver(
  update_driver_payload: UpdateDriverPayload
): Promise<Tables<"drivers"> | undefined> {
  const user = userSignal();
  if (!user) throw new Error("User not logged in");

  const device = myDevice();
  if (!device) throw new Error("No device");

  const { data, error } = await supabase.rpc("update_driver", {
    _device_id: device.id,
    _driver_status: update_driver_payload.driver_status ?? driverstatus(),
    _transportcompany_id:
      update_driver_payload.transportcompany_id === null
        ? undefined
        : update_driver_payload.transportcompany_id ?? currentTransportcompanyId(),
    _vehicle_id:
      update_driver_payload.vehicle_id === null ? undefined : update_driver_payload.vehicle_id ?? currentVehicleId(),
  });

  if (error) {
    if (error.message === "not_current_device") {
      sessionStorage.setItem("mooovex:update_driver_payload", JSON.stringify(update_driver_payload));
      dispatchEvent(new Event("not_current_device"));
    } else {
      throw new Error("Failed to update driver", { cause: error });
    }
  }

  if (data) {
    const updated_driver = data as Tables<"drivers">;

    setCurrentTransportcompanyId(updated_driver.current_transportcompany_id ?? undefined);
    setCurrentVehicleId(updated_driver.current_vehicle_id ?? undefined);
    setDriverstatus(updated_driver.status);

    return updated_driver;
  }
}

export async function fetchDriverStatus() {
  const user = userSignal();
  if (!user) throw new Error("User not logged in");
  const { data, error } = await supabase.from("drivers").select("status").eq("staff_id", user.id).single();

  if (error) throw error;

  setDriverstatus(data.status);
  return data.status;
}

export type Driverstatus = Tables<"drivers">["status"];

export const [driverstatus, setDriverstatus] = createSignal<Driverstatus>("outofservice");

export const isCurrentDriverstatus = createSelector(driverstatus);
