import { ct } from "@mooovex/components";
import { currentRoles } from "@mooovex/supabase-client";
import { A } from "@solidjs/router";
import { Component, createSignal, ParentProps, Show } from "solid-js";
import { Portal } from "solid-js/web";
import UserInfoC from "./UserInfo.c";

const [navbarActionsElement, setNavbarActionsElement] = createSignal<HTMLDivElement | null>(null);

const NavbarC: Component = () => {
  return (
    <>
      <nav class="navbar navbar-expand-md bg-white shadow sticky-top">
        <div class="container-fluid">
          <div class="tw-flex tw-gap-2" ref={setNavbarActionsElement} />
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-md-0">
              <li class="nav-item">
                <A class="nav-link" aria-current="page" href="/drivers">
                  {ct.driverlist.drivers()}
                </A>
              </li>
              <li class="nav-item">
                <A class="nav-link" aria-current="page" href="/pricelist">
                  {ct.price.pricelist()}
                </A>
              </li>
              <Show when={currentRoles().includes("owner")}>
                <li class="nav-item">
                  <A class="nav-link" aria-current="page" href="/transferList">
                    {ct.transferList.transferList()}
                  </A>
                </li>
                <li class="nav-item">
                  <A class="nav-link" aria-current="page" href="/toInvoice">
                    {ct.invoicing.toInvoice()}
                  </A>
                </li>
              </Show>
            </ul>
            <UserInfoC />
          </div>
        </div>
      </nav>
    </>
  );
};

export function NavbarActions(props: ParentProps) {
  return (
    <Show when={navbarActionsElement()}>
      <Portal ref={(ref) => (ref.style.display = "contents")} mount={navbarActionsElement()!}>
        {props.children}
      </Portal>
    </Show>
  );
}

export default NavbarC;
