import { interpolateColors } from "@mooovex/helpers";
import { SolidMapMarker } from "@mooovex/solid-map";
import { ParentComponent } from "solid-js";
type Props = {
  lngLat: number[];
  index: number;
  count: number;
};

export const MapPlaceMarker: ParentComponent<Props> = (props) => {
  const interpolatedColor = () =>
    `rgb(${interpolateColors([43, 113, 183], [35, 187, 76], props.index / Math.max(props.count - 1, 1)).join(",")})`;

  return (
    <SolidMapMarker lngLat={props.lngLat as [number, number]}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="27.38"
        height="27.38"
        viewBox="0 0 27.38 27.38"
        fill={interpolatedColor()}
      >
        <circle cx="13.69" cy="13.69" r="13.69" style="opacity:.2" />
        <circle cx="13.69" cy="13.69" r="6.03" />
      </svg>
    </SolidMapMarker>
  );
};
