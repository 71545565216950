import { useSolidMap } from "@mooovex/solid-map";
import MinusIcon from "lucide-solid/icons/minus";
import PlusIcon from "lucide-solid/icons/plus";
import { Component } from "solid-js";
import { Button } from "../ui/button";

type Props = {};

export const MapZoomControl: Component<Props> = (props) => {
  const map = useSolidMap();

  return (
    <div class="tw-flex tw-flex-col">
      <Button
        type="button"
        variant="outline"
        size="icon"
        onclick={() => map.zoomIn()}
        class="tw-rounded-b-none tw-border-b-0"
      >
        <PlusIcon />
      </Button>
      <Button
        type="button"
        variant="outline"
        size="icon"
        onclick={() => map.zoomOut()}
        class="tw-rounded-t-none tw-border-t-0"
      >
        <MinusIcon />
      </Button>
    </div>
  );
};
