import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-zod";
import logoUrl from "@mooovex/assets/images/mooovex_logo.svg";
import { A } from "@solidjs/router";
import LoaderCircleIcon from "lucide-solid/icons/loader-circle";
import { Component, createSignal, Show } from "solid-js";
import { z } from "zod";
import { ct } from "./i18n";
import * as localized from "./localized_schemas";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader } from "./ui/card";

const getSchema = () =>
  z
    .object({
      email: localized.email(),
      password: localized.requiredString().min(6, { message: ct.account.passwordMinLength(6) }),
      passwordRepeat: localized.requiredString(),
      name: localized.requiredString(),
      surname: localized.requiredString(),
      phone: localized.phoneNumber(),
    })
    .superRefine(({ password, passwordRepeat }, ctx) => {
      if (password !== passwordRepeat) {
        ctx.addIssue({
          code: "custom",
          path: ["passwordRepeat"],
          message: ct.validation.passwordsDontMatch(),
        });
      }
    });

type Schema = z.infer<ReturnType<typeof getSchema>>;

type Props = {
  onSubmit?: (registerData: Schema) => Promise<void>;
  loginHref?: string;
  email?: string;
};

function cleanupName(value: string) {
  return value
    .trim()
    .toLowerCase()
    .split(/\s+/g)
    .map((v) => v.charAt(0).toUpperCase() + v.slice(1))
    .join(" ");
}

export const RegisterForm: Component<Props> = (props) => {
  const [registerStatus, setRegisterStatus] = createSignal<"ready" | "error" | "loading" | "accountAlreadyExists">(
    "ready"
  );

  const { form, errors, isValid, data } = createForm<Schema>({
    extend: [validator({ schema: getSchema() })],
    onSubmit: async (values) => {
      const transformed = {
        email: values.email.trim().toLowerCase(),
        name: cleanupName(values.name),
        surname: cleanupName(values.surname),
        password: values.password,
        passwordRepeat: values.passwordRepeat,
        phone: values.phone.replaceAll(" ", ""),
      };

      setRegisterStatus("loading");
      try {
        await props.onSubmit?.(transformed);
        setRegisterStatus("ready");
      } catch (error) {
        if (error instanceof Error && error.message === "User already registered") {
          setRegisterStatus("accountAlreadyExists");
        } else {
          setRegisterStatus("error");
        }
      }
    },
    initialValues: { email: props.email, phone: "+39" },
  });

  return (
    <form ref={form} class="tw-flex tw-grow tw-items-center tw-justify-center tw-p-4">
      <Card class="tw-w-full tw-max-w-md tw-p-4">
        <CardHeader class="tw-flex tw-items-center tw-justify-center tw-pb-4">
          <img src={logoUrl} alt="logo" class="tw-h-auto tw-max-w-[150px]" />
        </CardHeader>
        <CardContent class="tw-flex tw-w-full tw-flex-col">
          {/* Form Section */}
          <fieldset disabled={registerStatus() === "loading"} class="tw-w-full">
            <div class="form-floating mb-1">
              <input
                name="email"
                type="email"
                class="form-control"
                classList={{ "is-invalid": !!errors().email?.length }}
                placeholder="name@example.com"
              />
              <label for="email" class="form-label">
                {ct.account.email._()}
              </label>
              <div class="text-danger" classList={{ invisible: !errors().email?.[0] }}>
                {errors().email?.[0] ?? "-"}
              </div>
            </div>
            <div class="form-floating mb-1">
              <input
                name="password"
                type="password"
                class="form-control"
                classList={{ "is-invalid": !!errors().password?.length }}
                placeholder={ct.account.password()}
              />
              <label for="password" class="form-label">
                {ct.account.password()}
              </label>
              <div class="text-danger" classList={{ invisible: !errors().password?.[0] }}>
                {errors().password?.[0] ?? "-"}
              </div>
            </div>
            <div class="form-floating mb-1">
              <input
                name="passwordRepeat"
                type="password"
                class="form-control"
                classList={{
                  "is-invalid": !!errors().passwordRepeat?.length,
                }}
                placeholder={ct.account.passwordRepeat()}
              />
              <label for="passwordRepeat" class="form-label">
                {ct.account.passwordRepeat()}
              </label>
              <div class="text-danger" classList={{ invisible: !errors().passwordRepeat?.[0] }}>
                {errors().passwordRepeat?.[0] ?? "-"}
              </div>
            </div>
            <div class="row g-3">
              <div class="col">
                <div class="col form-floating mb-1">
                  <input
                    name="name"
                    type="text"
                    class="form-control"
                    classList={{ "is-invalid": !!errors().name?.length }}
                    placeholder={ct.account.name()}
                  />
                  <label for="name" class="form-label">
                    {ct.account.name()}
                  </label>
                  <div class="text-danger" classList={{ invisible: !errors().name?.[0] }}>
                    {errors().name?.[0] ?? "-"}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-floating mb-1">
                  <input
                    name="surname"
                    type="text"
                    class="form-control"
                    classList={{ "is-invalid": !!errors().surname?.length }}
                    placeholder={ct.account.surname()}
                  />
                  <label for="surname" class="form-label">
                    {ct.account.surname()}
                  </label>
                  <div class="text-danger" classList={{ invisible: !errors().surname?.[0] }}>
                    {errors().surname?.[0] ?? "-"}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-floating mb-1">
              <input
                name="phone"
                type="tel"
                class="form-control"
                placeholder={ct.account.phoneNumber()}
                classList={{ "is-invalid": !!errors().phone?.length }}
              />
              <label for="phone" class="form-label">
                {ct.account.phoneNumber()}
              </label>
              <div class="text-danger" classList={{ invisible: !errors().phone?.[0] }}>
                {errors().phone?.[0] ?? "-"}
              </div>
            </div>

            <Show when={registerStatus() === "error"}>
              <div class="alert alert-danger">{ct.validation.register.error()}</div>
            </Show>

            <Show when={registerStatus() === "accountAlreadyExists"}>
              <div class="alert alert-danger">
                {ct.validation.register.emailAlreadyExists()}
                &nbsp;
                <A href={`/resetPassword?email=${data().email}`} class="tw-underline">
                  {ct.account.resetPassword()}
                </A>
              </div>
            </Show>

            <Button size="lg" class="tw-w-full tw-gap-2" block type="submit" disabled={!isValid()}>
              <Show when={registerStatus() === "loading"}>
                <LoaderCircleIcon class="tw-animate-spin" />
              </Show>
              {ct.account.register()}
            </Button>
            <Show when={props.loginHref}>
              <A href={props.loginHref!} class="form-text tw-underline">
                {ct.account.login()}
              </A>
            </Show>
          </fieldset>
        </CardContent>
      </Card>
    </form>
  );
};

export default RegisterForm;
