import * as API from "@mooovex/api-schema";
import { createSortable } from "@thisbeyond/solid-dnd";
import { Component } from "solid-js";
import { removeDestination, removeOrigin } from "../../services/state/newpricelist.state";

type Props = {
  place: API.google_place_details.ResponseBody;
  type: "origin" | "destination";
  sortable?: ReturnType<typeof createSortable>;
};

export const PlaceTD: Component<Props> = (props) => {
  function remove() {
    (props.type === "origin" ? removeOrigin : removeDestination)(API.PlaceAdapter.getId(props.place));
  }

  const sortable = createSortable(API.PlaceAdapter.getId(props.place));

  return (
    <td
      ref={(ref) => {
        if (!props.sortable) sortable(ref);
      }}
    >
      <i class="bi-grip-vertical me-2 cursor-move" {...(props.sortable ?? sortable).dragActivators} />
      <i class="bi-trash me-2 cursor-pointer" onclick={remove} />
      &nbsp;<span>{props.place.name}</span>
    </td>
  );
};

export default PlaceTD;
