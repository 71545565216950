import { QrCode, QrCodeRootProps } from "@ark-ui/solid/qr-code";
import { VoidProps } from "solid-js";

function Component(props: VoidProps<QrCodeRootProps>) {
  return (
    <QrCode.Root {...props}>
      <QrCode.Frame>
        <QrCode.Pattern />
      </QrCode.Frame>
    </QrCode.Root>
  );
}

export { Component as QrCode };
