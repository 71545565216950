import { Buffer, wkx } from "@mooovex/wkx";
import { z } from "zod";
import { MyCoordinates2D } from "./MyCoordinates2D.class";
import { Clonable, Serializable } from "./utils";

export class MyCoordinates3D implements Serializable<[number, number, number]>, Clonable<MyCoordinates3D> {
  private myCoordinates2d: MyCoordinates2D;

  static serialized = z.tuple([z.number().min(-180).max(180), z.number().min(-90).max(90), z.number()]);

  constructor(
    lng: number,
    lat: number,
    public alt: number
  ) {
    this.myCoordinates2d = new MyCoordinates2D(lng, lat);
  }

  static fromJSON(value: [number, number, number]): MyCoordinates3D {
    return new MyCoordinates3D(value[0], value[1], value[2]);
  }

  static fromLngLatAlt(value: [number, number, number]): MyCoordinates3D {
    return new MyCoordinates3D(value[0], value[1], value[2]);
  }

  static fromLatLngAlt(value: [number, number, number]): MyCoordinates3D {
    return new MyCoordinates3D(value[1], value[0], value[2]);
  }

  static fromLngLatAltString(value: string): MyCoordinates3D {
    const [lng, lat, alt] = value
      .replaceAll(" ", "")
      .split(",")
      .map((v) => parseFloat(v));
    return new MyCoordinates3D(lng, lat, alt);
  }

  static fromLatLngAltString(value: string): MyCoordinates3D {
    const [lat, lng, alt] = value
      .replaceAll(" ", "")
      .split(",")
      .map((v) => parseFloat(v));
    return new MyCoordinates3D(lng, lat, alt);
  }

  static fromMyCoordinates2D(value: MyCoordinates2D, alt: number = 0) {
    return new MyCoordinates3D(value.lng, value.lat, alt);
  }

  static fromWkx(value: wkx.Point) {
    return new MyCoordinates3D(value.x, value.y, value.z);
  }

  static fromWkb(value: Buffer | string) {
    if (typeof value === "string") value = Buffer.from(value, "hex");
    return MyCoordinates3D.fromWkx(wkx.Geometry.parse(value) as wkx.Point);
  }

  toLngLatAlt() {
    return [this.myCoordinates2d.lng, this.myCoordinates2d.lat, this.alt] satisfies [number, number, number];
  }

  toLatLngAlt() {
    return [this.myCoordinates2d.lat, this.myCoordinates2d.lng, this.alt] satisfies [number, number, number];
  }

  toLngLatAltString(fractionDigits?: number) {
    return `${this.myCoordinates2d.toLngLatString(fractionDigits)},${fractionDigits !== undefined ? this.alt.toFixed(fractionDigits) : this.alt}`;
  }

  toLatLngAltString(fractionDigits?: number) {
    return `${this.myCoordinates2d.toLatLngString(fractionDigits)},${fractionDigits !== undefined ? this.alt.toFixed(fractionDigits) : this.alt}`;
  }

  toGoogleLocation() {
    return this.myCoordinates2d.toGoogleLocation();
  }

  toMyCoordinates2D() {
    return this.myCoordinates2d.clone();
  }

  clone(): MyCoordinates3D {
    return new MyCoordinates3D(this.myCoordinates2d.lng, this.myCoordinates2d.lat, this.alt);
  }

  toJSON(): [number, number, number] {
    return this.toLngLatAlt();
  }

  toString() {
    return this.toLatLngAltString();
  }

  toWkx() {
    return new wkx.Point(this.lng, this.lat, this.alt);
  }

  toWkb() {
    return this.toWkx().toWkb();
  }

  toGeoJSON() {
    return {
      type: "Point",
      coordinates: this.toLngLatAlt(),
    } as const;
  }

  get lng() {
    return this.myCoordinates2d.lng;
  }

  get lat() {
    return this.myCoordinates2d.lat;
  }
}
