import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-zod";
import { client } from "@mooovex/api-client";
import logoUrl from "@mooovex/assets/images/mooovex_logo.svg";
import { QrCode } from "@mooovex/components/ui/qr-code";
import { signInWithRefreshToken } from "@mooovex/supabase-client";
import { A } from "@solidjs/router";
import LoaderCircleIcon from "lucide-solid/icons/loader-circle";
import { Component, createSignal, onCleanup, ParentProps, Show } from "solid-js";
import { z } from "zod";
import { ct } from "./i18n";
import * as localized from "./localized_schemas";
import { setLastUsedEmail } from "./storage";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader } from "./ui/card";

const getSchema = () =>
  z.object({
    email: localized.email(),
    password: localized.requiredString(),
  });

type Schema = z.infer<ReturnType<typeof getSchema>>;

type Props = {
  onSubmit?: (loginData: Schema) => Promise<void>;
  resetPasswordHref?: string;
  registerHref?: string;
  email?: string;
};

export const LoginForm: Component<ParentProps<Props>> = (props) => {
  const [loginStatus, setLoginStatus] = createSignal<"ready" | "error" | "loading">("ready");

  const { form, errors, isValid, resetField, data } = createForm<Schema>({
    extend: [validator({ schema: getSchema() })],
    initialValues: {
      email: props.email,
    },
    onSubmit: async (values) => {
      setLoginStatus("loading");
      try {
        await props.onSubmit?.(values);
        setLoginStatus("ready");
        setLastUsedEmail(values.email);
      } catch (error) {
        console.log(error);
        setLoginStatus("error");
        resetField("password");
      }
    },
  });

  const resetPasswordHref = () => props.resetPasswordHref && `${props.resetPasswordHref}?email=${data().email}`;
  const registerHref = () => props.registerHref && `${props.registerHref}?email=${data().email}`;

  const [qrCodeURL, setQrCodeURL] = createSignal<string>();

  const eventSource = new EventSource(client.v2.auth.qr.$url());
  eventSource.onmessage = async ({ data }) => {
    data = JSON.parse(data);
    if (data.type === "token") {
      const token: string = data.token;
      const url = new URL(import.meta.env.VITE_FRONTEND_URL);
      url.pathname = "/apps/driver/auth";
      url.searchParams.set("token", token);
      setQrCodeURL(url.toString());
    } else if (data.type === "confirmed") {
      const refreshToken: string = data.refreshToken;
      await signInWithRefreshToken(refreshToken);
    }
  };

  onCleanup(() => {
    eventSource.close();
  });

  return (
    <form ref={form} class="tw-flex tw-grow tw-items-center tw-justify-center tw-p-4">
      <Card class="tw-w-full tw-max-w-md tw-p-4 sm:tw-max-w-2xl">
        <CardHeader class="tw-flex tw-items-center tw-justify-center tw-pb-4">
          <img src={logoUrl} alt="logo" class="tw-h-auto tw-max-w-[150px]" />
        </CardHeader>
        <CardContent class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 sm:tw-flex-row">
          {/* Form Section */}
          <div class="tw-flex tw-w-full tw-flex-col sm:tw-w-1/2">
            <fieldset disabled={loginStatus() === "loading"} class="tw-w-full">
              <div class="form-floating mb-1">
                <input
                  name="email"
                  type="email"
                  class="form-control"
                  classList={{ "is-invalid": !!errors().email?.length }}
                  placeholder="name@example.com"
                />
                <label for="email" class="form-label">
                  {ct.account.email._()}
                </label>
                <div class="text-danger" classList={{ invisible: !errors().email?.length }}>
                  {errors().email?.[0] ?? "-"}
                </div>
              </div>
              <div class="form-floating mb-1">
                <input
                  name="password"
                  type="password"
                  class="form-control"
                  classList={{ "is-invalid": !!errors().password?.length }}
                  placeholder={ct.account.password()}
                />
                <label for="password" class="form-label">
                  {ct.account.password()}
                </label>
                <div class="text-danger" classList={{ invisible: !errors().password?.length }}>
                  {errors().password?.[0] ?? "-"}
                </div>
                <Show when={resetPasswordHref()}>
                  <A href={resetPasswordHref()!} class="form-text tw-underline">
                    {ct.account.resetPassword()}
                  </A>
                </Show>
              </div>
              <Show when={loginStatus() === "error"}>
                <div class="alert alert-danger">{ct.validation.login.error()}</div>
              </Show>
              <Button size="lg" class="tw-w-full tw-gap-2" block type="submit" disabled={!isValid()}>
                <Show when={loginStatus() === "loading"}>
                  <LoaderCircleIcon class="tw-animate-spin" />
                </Show>
                {ct.account.login()}
              </Button>
              {props.children}
              <Show when={registerHref()}>
                <A href={registerHref()!} class="form-text tw-underline">
                  {ct.account.register()}
                </A>
              </Show>
            </fieldset>
          </div>
          {/* QR Code Section */}
          <Show when={qrCodeURL()}>
            <div class="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-text-center sm:tw-w-1/2">
              <QrCode value={qrCodeURL()} class="tw-h-auto tw-w-[200px]" />
              <h1 class="tw-text-xl tw-font-bold">{ct.qrLogin.loginWithQrCode()}</h1>
              <p class="tw-text-muted-foreground">{ct.qrLogin.description()}</p>
            </div>
          </Show>
        </CardContent>
      </Card>
    </form>
  );
};

export default LoginForm;
