import type { Dict } from "./en";
import * as BaseFormatters from "./formatters";

export const dict: Dict = {
  formatters: {
    kmh: BaseFormatters.kmh("it"),
    mps: BaseFormatters.mps("it"),
    humanDuration: BaseFormatters.humanDuration("it"),
    duration: BaseFormatters.duration,
    kilometers: BaseFormatters.kilometers("it"),
    meters: BaseFormatters.meters("it"),
    euros: BaseFormatters.euros("it"),
    relativeTime: BaseFormatters.relativeTime("it"),
    date: BaseFormatters.date("it"),
    time: BaseFormatters.time("it"),
    percent: BaseFormatters.percent("it"),
  },
  account: {
    _: "Account",
    login: "Accedi",
    register: "Registrati",
    email: {
      _: "Email",
      confirm: {
        title: "Conferma email",
        message:
          "Il tuo account è stato creato. Devi confermare il tuo indirizzo email prima di poter effettuare l'accesso",
      },
    },
    password: "Password",
    passwordMinLength: (minLength: number) => `La password deve avere almeno ${minLength} caratteri`,
    resetPassword: "Reimposta password",
    passwordRepeat: "Ripeti password",
    name: "Nome",
    surname: "Cognome",
    phoneNumber: "Numero di telefono",
    passwordResetInstructions: "Invieremo le istruzioni su come reimpostare la password a questo indirizzo email",
    sixdigitcode: "Codice a 6 cifre",
    codeVerifyFailed:
      "Impossibile verificare il codice. Questo codice è valido solo per un periodo di tempo limitato e può essere utilizzato una sola volta. Si prega di riprovare.",
    logout: "Esci",
    logoutFail: "Disconnessione fallita",
    statusBeforeLogout: "Imposta il tuo status su 'fuori servizio' prima di uscire",
    create: "Crea account",
    alreadyHave: "Ho già un account",
    changePassword: "Cambia password",
    delete: {
      _: "Cancella account",
      warning:
        "Dopo aver inserito la tua password, il tuo account verrà contrassegnato per la cancellazione e sarà cancellato da un amministratore. Questa azione è irreversibile.",
      confirmation: {
        title: "Account cancellato",
        message:
          "Il tuo account è stato contrassegnato per la cancellazione e verrà eliminato da un amministratore. Ti invieremo un'email quando questo processo sarà completato. Se si tratta di un errore, ti preghiamo di contattarci immediatamente: info@mooovex.com",
      },
      error: "Impossibile cancellare il tuo account. Controlla la tua password",
    },
    created: {
      title: "Grazie per esserti registrato su Mooovex",
      message: (email: string) =>
        `Per attivare il tuo account, segui le istruzioni ricevute via email all'indirizzo ${email}`,
    },
  },
  validation: {
    passwordMinLength: "La password deve contenere almeno 8 caratteri",
    emailInvalid: "Email non valida",
    passwordsDontMatch: "Le password non corrispondono",
    requiredField: "Obbligatorio",
    invalidPhone: "Numero di telefono non valido",
    login: {
      error: "Accesso non riuscito, controlla email e password",
    },
    register: {
      error: "Registrazione non riuscita, riprova più tardi",
      emailAlreadyExists:
        "Un account con questo indirizzo e-mail esiste già. Se non ricordi la password, puoi resettarla.",
    },
    passwordResetFail: "Reimpostazione della password non riuscita, controlla l'email",
    passwordResetSuccess: (email: string) =>
      `Se esiste un account con l'indirizzo e-mail ${email}, è stata inviata un'e-mail con istruzioni su come reimpostare la password`,
    passwordChange: {
      error: "Cambio password non riuscito",
      success: "La tua password è stata cambiata con successo",
    },
  },
  common: {
    troubleshooting: "Risoluzione dei problemi",
    noResults: "Nessun risultato",
    allow: "Consenti",
    cancel: "Annulla",
    continue: "Continua",
    back: "Indietro",
    user: "Utente",
    company: "Ditta",
    companies: "Ditte",
    yourCompanies: "Le tue ditte",
    language: "Lingua",
    yes: "Si",
    no: "No",
    ok: "OK",
    abort: "Cancella",
    date: "Data",
    time: "Ora",
    default: "Predefinito",
    normal: "Normale",
    rightClickToCopy: "Clic destro per copiare",
    tryAgain: "Riprova",
    error: "Errore",
    close: "Chiudi",
    save: "Salva",
    name: "Nome",
    email: "Email",
    phoneNumber: "Numero di telefono",
    reset: "Resetta",
    delete: "Elimina",
    refresh: "Aggiorna",
    loading: "Caricamento",
    search: "Cerca",
    total: "Totale",
    sum: "Somma",
  },
  ride: {
    duration: "Durata",
    distance: "Distanza",
    notriptext: "Inserisci il punto di partenza e di arrivo del percorso per ottenere i dettagli",
    reset: { _: "Resetta viaggio" },
    start: { _: "Inizia viaggio" },
    conclude: {
      _: "Concludi viaggio",
      confirm: "Sei sicuro di voler concludere il viaggio?",
      error: "Non riuscito concludere il viaggio",
    },
    abort: {
      _: "Interrompi viaggio",
      confirm: "Sei sicuro di voler interrompere il viaggio?",
    },
    waypoint: "Tappa",
    stopover: { _: "Fermata" },
    resume: { _: "Riprendi viaggio" },
    origin: {
      _: "Punto di partenza",
      keep: {
        title: "Punto di partenza già impostato",
        message: "Hai già impostato un punto di partenza. Vuoi mantenerlo o utilizzare la tua posizione attuale?",
      },
    },
    destination: {
      _: "Destinazione",
      keep: {
        title: "Destinazione già impostata",
        message: "Hai già impostato una destinazione. Vuoi mantenerla o utilizzare la tua posizione attuale?",
      },
    },
    personCount: "Numero persone",
    currentDate: "Data attuale",
    currentTime: "Ora attuale",
    calculatingRoute: "Calcolo del percorso",
    currentLocation: "Posizione attuale",
    failedToGetCurrentLocation: "Impossibile ottenere la posizione attuale",
    route: {
      calculation: {
        error: "Calcolo del tragitto non riuscito",
      },
    },
    place: {
      keep: "Mantieni",
      useCurrentLocation: "Usa la posizione attuale",
    },
  },
  price: {
    discount: "Sconto",
    feedback: {
      _: "Feedback prezzo",
      tooHigh: "Troppo alto",
      tooLow: "Troppo basso",
    },
    calculation: {
      _: "Calcolo del prezzo",
      error: "Impossibile calcolare il prezzo",
    },
    pricelist: "Lista prezzi",
  },
  vehicleSelection: {
    companyAndVehicle: "Azienda e veicolo",
    company: "Azienda",
    vehicle: "Veicolo",
    vehicleModel: "Modello",
    noCompanies: "Nessuna azienda disponibile",
    noVehicles: "Nessun veicolo disponibile",
    noCompany: "Nessuna azienda",
    noVehicle: "Nessun veicolo",
    availableCompanies: {
      error: "Impossibile caricare le aziende disponibili",
    },
    availableVehicles: {
      error: "Impossibile caricare i veicoli disponibili",
    },
    selectVehicle: {
      error: "Selezione del veicolo non riuscita",
    },
  },
  driverlist: {
    noDrivers: "Nessun conducente",
    drivers: "Conducenti",
    visibleDrivers: {
      error: "Impossibile caricare i conducenti",
    },
    noZipcode: "Nessun codice postale",
  },
  network: {
    noInternet: "Nessuna connessione internet",
  },
  driver: {
    setStatus: {
      error: "Impossibile impostare lo status",
    },
    status: {
      notOutofservice: {
        title: "Status non è 'Fuori servizio'",
        message: "Imposta il tuo status su 'Fuori servizio' prima di uscire",
      },
    },
    vehicle: {
      noVehicleChosen: {
        title: "Scegli veicolo",
        message: "Scegli un veicolo per andare online",
      },
    },
  },
  poi: {
    fetch: {
      error: "Impossibile caricare i luoghi",
    },
    google: {
      error: "Impossibile caricare i luoghi di Google",
    },
  },
  user: {
    info: {
      error: "Impossibile caricare le informazioni utente",
    },
  },
  permissions: {
    geolocation: {
      backgroundPermissionRationale: {
        title: "Consenti a {applicationName} di accedere alla tua posizione in background",
        message:
          "Consentire sempre i servizi di localizzazione garantirà che la tua posizione possa essere condivisa in modo affidabile anche quando Mooovex è in esecuzione in background.",
      },
      denied: "Apri le impostazioni dell'applicazione e modifica manualmente l'autorizzazione",
      rationale: {
        title: "L'attivazione delle funzioni di localizzazione ci consente di",
        features: {
          shareLocation: "Condividere la tua posizione con i tuoi colleghi conducenti",
          assignRides: "Assegnarti corse più vicine a te",
          calculateRoutes: "Calcolare le rotte più ottimizzate per le tue corse",
        },
        backgroundInfo:
          "Inoltre, consentire l'accesso alla posizione mentre l'app è in esecuzione in background migliora notevolmente l'affidabilità delle funzioni menzionate quando lo schermo è spento o hai un'altra app in primo piano.",
        changeLater: "Puoi cambiare questa opzione nelle impostazioni",
      },
      alwaysWarning: `Per condividere la tua posizione, imposta l'autorizzazione alla posizione su "Sempre"`,
    },
    openSettings: "Apri impostazioni",
    allowBackground: "Cambiare a '{backgroundPermissionOptionLabel}'",
  },
  taxistations: "Stazione taxi",
  geolocation: {
    notification: {
      title: "Tracciamento posizione",
      message:
        "Mooovex sta registrando la tua posizione. Per disattivare il rilevamento della posizione, imposta il tuo status su 'Fuori servizio'",
    },
    get: {
      failed: "Impossibile acquisire la tua posizione",
    },
    enableInSettings: "Abilita i servizi di localizzazione nelle impostazioni",
  },
  devices: {
    _: "Dispositivi",
    noDevices: "Nessun dispositivo",
    thisDevice: "Questo dispositivo",
    override: {
      title: "Inviare la posizione del veicolo con questo dispositivo?",
      message:
        "Attualmente un altro dispositivo collegato al tuo account sta inviando la posizione del veicolo. Desideri che il tuo dispositivo invii la posizione del veicolo al posto dell'altro dispositivo?",
    },
  },
  transportcompany: {
    logout: { _: "Uscire dall'azienda", fail: "Uscire dall'azienda non riuscito" },
    failedToChoose: "Impossibile scegliere l'azienda",
    failedToUpdateGlobalStatusFilter: "Impossibile aggiornare il filtro del status globale",
    failedToUpdateVisibility: "Impossibile aggiornare la visibilità",
  },
  vehicle: {
    deselect: "Rilascia veicolo",
  },
  updates: {
    new: {
      title: (version: string) => `Aggiornamento ${version} disponibile`,
      message:
        "È disponibile un nuovo aggiornamento, l'app deve essere riavviata per scaricare e installare questo aggiornamento. Riavviare ora?",
    },
    appStore: {
      title: "Nuova versione disponibile sull'App Store",
      message: (versionString: string) => `Per favore scarica la versione ${versionString} dall'App Store`,
      button: "Apri App Store",
    },
    searchingForUpdates: "Ricerca di aggiornamenti",
    downloadingUpdate: (version: string) => `Scaricando aggiornamento ${version}`,
    installingUpdate: (version: string) => `Installando aggiornamento ${version}`,
  },
  place: {
    search: {
      favorites: "Preferiti",
      history: "Cronologia",
      results: "Risultati",
    },
    airportHotelAddress: "Aeroporto, Albergo, Indirizzo...",
    couldNotFindPlaceName: "Impossibile trovare il nome del luogo",
    failedToGetDetails: "Impossibile caricare i dettagli del luogo",
    failedToGetPlaces: "Impossibile caricare i luoghi",
  },
  service: "Servizio",
  settings: {
    _: "Impostazioni",
    biometrics: {
      _: "Biometria",
      deleteCredentials: "Elimina credenziali biometriche",
      delete: {
        title: "Vuoi eliminare le tue credenziali biometriche?",
        message: "Non sarà più possibile accedere tramite la biometria",
        confirm: "Credenziali biometriche eliminate",
        failed: "Errore: Impossibile eliminare le credenziali biometriche",
      },
      setup: {
        title: "Imposta biometria",
        message: "Configurando la verifica biometrica, puoi accedere senza utilizzare la password",
      },
      logoutForBiometricSetup: "Verrai disconnesso per configurare la verifica biometrica",
      failedToSaveBiometricCredentials: "Impossibile salvare le credenziali biometriche",
      savedCredentials: "Credenziali salvate",
      verificationFailed: "Verifica fallita",
      noCredentialsFound: "Nessuna credenziale trovata",
      loginWithBiometrics: "Accedi con biometria",
    },
    replaceLocation: "Sostituire la posizione all'inizio/fine viaggio",
    driverstatusInDropdown: "Selezione status nel menu in basso",
    rideFormLandscapeRightSide: "Mostra il modulo a destra in formato orizzontale",
    largerVehicleIcons: "Icone veicoli più grandi",
    hideConcludeConfirm: "Nascondi la conferma di conclusione del viaggio",
    debugMode: "Modalità Debug",
  },
  locale: {
    failedToChange: "Non riuscito cambiare lingua",
  },
  transfers: {
    transferCreateError: "Impossibile creare il transfer",
    waypointCreateError: "Impossibile creare il waypoint",
    transferPauseError: "Impossibile mettere in pausa il transfer",
    transferResumeError: "Impossibile riprendere il transfer",
    waypointSetWaitTimeError: "Impossibile impostare il tempo di attesa",
    transferConcludeError: "Impossibile concludere il transfer",
    transferAbortError: "Impossibile interrompere il transfer",
    routeSegmentGenerateError: "Impossibile generare il segmento del percorso",
    delete: {
      confirm: {
        title: "Eliminare il transfer",
        message: "Sei sicuro di voler eliminare questo transfer?",
      },
      error: "Impossibile eliminare il transfer",
    },
    saved: "Il transfer è stato salvato",
    deleted: "Il transfer è stato eliminato",
  },
  transferSummary: {
    paymentMethod: {
      cash: "Contanti",
      card: "Carta",
      invoice: "Fattura",
    },
    comments: "Commenti",
    testMode: "Modalità test",
    client: "Cliente",
    invoiceTo: "Fattura a",
    priceOptions: {
      _: "Opzioni prezzo",
      feedback: {
        _: "Feedback prezzo",
        tooHigh: "Troppo alto",
        tooLow: "Troppo basso",
      },
      actualPrice: "Prezzo effettivo",
      description:
        "Indica se il prezzo è troppo alto o troppo basso e, opzionalmente, inserisci una tariffa corretta per discrepanze significative. Il tuo feedback ci aiuta a migliorare la precisione dei prezzi",
    },
    open: "Apri riepilogo",
  },
  driverlocations: {
    failedToFetch: "Non è stato possibile recuperare le posizioni dei conducenti",
  },
  transferList: {
    transferList: "Lista Viaggi",
    confirmDeleteTransfersOfDate: {
      title: "Cancella viaggi",
      message: (date: Date) => `Sei sicuro di voler cancellare i viaggi del ${dict.formatters.date(date)}?`,
    },
    transfersOfDateHaveBeenDeleted: (date: Date) => `I viaggi del ${dict.formatters.date(date)} sono stati cancellati`,
    failedToDeleteTransfersOfDate: (date: Date) =>
      `Non è stato possibile cancellare i viaggi del ${dict.formatters.date(date)}`,
    driversDailyTotal: "Totale giornaliero autista",
    dailyTotal: "Totale giornaliero",
  },
  order: {
    ascending: "Ascendente",
    descending: "Discendente",
    newFirst: "Più recenti prima",
    oldFirst: "Più vecchi prima",
    aToZ: "A a Z",
    zToA: "Z a A",
  },
  visibility: {
    _: "Visibilità",
    global: "Globale",
    partner: "Partner",
    justMe: "Solo io",
  },
  driverstatus: {
    available: "Disponibile",
    empty: "Verso Ritiro",
    occupied: "Verso Destinazione",
    outofservice: "Fuori Servizio",
  },
  transportcompanies: {
    partnerships: "Collaborazioni",
    yourPartners: "I tuoi partner",
    youArePartnerOf: "Sei partner di",
    otherCompanies: "Altre aziende",
    endPartnership: "Termina collaborazione",
    failedToSave: "Non riuscito a salvare",
    partnerAddWarning: "Se aggiungi una azienda come partner, verrà informato su questo",
  },
  mooovexDriver: {
    appRequired: "Devi scaricare l'app Mooovex Driver per utilizzare questa funzione",
  },
  qrLogin: {
    loginWithQrCode: "Accedi con QR Code",
    description:
      "Scansiona questo QR Code con un altro dispositivo con Mooovex Driver installato per accedere istantaneamente",
    youNeedToBeLoggedIn: "Devi essere autenticato per eseguire questa azione",
    confirmLogin: "Vuoi confermare l'accesso sull'altro dispositivo?",
    loginSuccessful: "Accesso effettuato con successo",
    loginFailed: "Accesso non riuscito",
  },
  notifications: {
    _: "Notifiche",
    noNotifications: "Nessuna notifica",
    failedToSave: "Non riuscito a salvare la notifica",
  },
  invoicing: {
    _: "Fatturazione",
    toInvoice: "Da fatturare",
    recipientsMonthlyTotal: "Totale mensile destinatario fattura",
    monthlyTotal: "Totale mensile",
    months: "Mesi",
    invoiceRecipients: "Destinatari fattura",
    transfers: "Viaggi",
    local: (city: string) => `Locale ${city}`,
  },
};
