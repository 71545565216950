import * as mooovexApiClient from "@mooovex/api-client";
import * as API from "@mooovex/api-schema";
import { makeUnknownPlace, MyCoordinates2D, MyDate, MyTime, uri } from "@mooovex/helpers";
import { captureException } from "@sentry/browser";
import { makePersisted } from "@solid-primitives/storage";
import { Accessor, batch, createContext, createMemo, createUniqueId, ParentComponent, useContext } from "solid-js";
import { createStore, produce, SetStoreFunction, Store } from "solid-js/store";
import { ct, currentLocale } from "./i18n";

type TransferFormStoreType = {
  centeredDriverId: string | undefined;
  placeSearchFavorites: API.google_autocomplete.ResponseBodyItem[];
  placeSearchHistory: API.google_autocomplete.ResponseBodyItem[];
  priceOptionsVisible: boolean;
  passengerCountVisible: boolean;
  placeSearchVisible: boolean;
  formVisible: boolean;
  form: {
    route: API.route.ResponseBody | undefined;
    price: number | undefined;
    priceOptionsSelectedPresetIndex: number;
    discount: number;
    passengerCount: number | undefined;
    startTime: MyTime | undefined;
    startDate: MyDate | undefined;
    placeContainers: {
      containerId: string;
      place: API.google_place_details.ResponseBody | undefined;
    }[];
    placeSearchTargetContainerId: string | undefined;
    routeLoading: boolean;
    priceLoading: boolean;
  };
};

function getInitialFormState(): TransferFormStoreType["form"] {
  return {
    priceOptionsSelectedPresetIndex: 0,
    discount: 0,
    placeContainers: [
      { containerId: createUniqueId(), place: undefined },
      { containerId: createUniqueId(), place: undefined },
    ],
    placeSearchTargetContainerId: undefined,
    routeLoading: false,
    priceLoading: false,
    passengerCount: undefined,
    startTime: undefined,
    startDate: undefined,
    route: undefined,
    price: undefined,
  };
}
const [transferForm, setTransferForm] = makePersisted(
  createStore<TransferFormStoreType>({
    form: getInitialFormState(),
    centeredDriverId: undefined,
    placeSearchFavorites: [],
    placeSearchHistory: [],
    priceOptionsVisible: false,
    placeSearchVisible: false,
    formVisible: true,
    passengerCountVisible: false,
  }),
  {
    name: "mooovex:transferForm",
    serialize(data) {
      return JSON.stringify({
        ...data,
        form: {
          ...data.form,
          startTime: data.form.startTime?.toJSON(),
          startDate: data.form.startDate?.toJSON(),
        },
      });
    },
    deserialize(data) {
      const parsed = JSON.parse(data);
      return {
        ...parsed,
        form: {
          ...parsed.form,
          startTime: parsed.form.startTime !== undefined ? MyTime.fromJSON(parsed.form.startTime) : undefined,
          startDate: parsed.form.startDate !== undefined ? MyDate.fromJSON(parsed.form.startDate) : undefined,
        },
      };
    },
  }
);

type Context = [
  Store<TransferFormStoreType>,
  SetStoreFunction<TransferFormStoreType>,
  {
    placesInWaypoints: Accessor<API.google_place_details.ResponseBody[]>;
    openPlaceSearchOverlay: (targetContainerId: string) => void;
    closePlaceSearchOverlay: () => void;
    openPlaceInGoogleMaps: (placeOrCoordinates: API.google_place_details.ResponseBody | MyCoordinates2D) => void;
    setPlaceToCurrentLocation: (containerId: string) => void;
    showErrorMessage: (message: string) => void;
    showTimePicker: (initialTime?: MyTime) => void;
    showDatePicker: (initialDate?: MyDate) => void;
    setPlaceContainer: (containerId: string, place: API.google_place_details.ResponseBody | undefined) => void;
    clearForm: () => void;
    /**
     * Adds a new place container to the form's place containers array.
     *
     * @param place - Optional place details to initialize the container with
     * @param index - Optional insertion position. If negative, the position is calculated from
     *                the end of the array (e.g., -1 inserts at the end). Defaults to -1.
     * @returns The unique ID of the newly created place container
     */
    addPlaceContainer: (place?: API.google_place_details.ResponseBody, index?: number) => string;
    getCurrentPlace: () => Promise<API.google_place_details.ResponseBody>;
  },
];

export const TransferFormContext = createContext<Context>();

type Props = {
  getCurrentLocation: () => Promise<MyCoordinates2D>;
  showTimePicker: (
    store: [Store<TransferFormStoreType>, SetStoreFunction<TransferFormStoreType>],
    initialTime?: MyTime
  ) => void;
  showDatePicker: (
    store: [Store<TransferFormStoreType>, SetStoreFunction<TransferFormStoreType>],
    initialDate?: MyDate
  ) => void;
  showErrorMessage: (message: string) => void;
};

export const TransferFormProvider: ParentComponent<Props> = (props) => {
  // This is a memo to prevent the route recalculation when adding/moving empty waypoints
  const placesInWaypoints = createMemo(
    () => transferForm.form.placeContainers.flatMap((c) => c.place ?? []),
    undefined,
    // compare all by id
    { equals: (a, b) => a.length === b.length && a.every((place, i) => place.google_place_id === b[i].google_place_id) }
  );

  return (
    <TransferFormContext.Provider
      value={[
        transferForm,
        setTransferForm,
        {
          placesInWaypoints,
          openPlaceSearchOverlay: (targetContainerId) => {
            batch(() => {
              setTransferForm("form", "placeSearchTargetContainerId", targetContainerId);
              setTransferForm("placeSearchVisible", true);
            });
          },
          closePlaceSearchOverlay: () => {
            batch(() => {
              setTransferForm("form", "placeSearchTargetContainerId", undefined);
              setTransferForm("placeSearchVisible", false);
            });
          },
          openPlaceInGoogleMaps: (placeOrCoordinates) => {
            let url: string;
            if (placeOrCoordinates instanceof MyCoordinates2D) {
              url = uri`https://www.google.com/maps/search/?api=1&query=${placeOrCoordinates.toLatLngString()}`;
            } else {
              url = uri`https://www.google.com/maps/search/?api=1&query=${placeOrCoordinates.name}&query_place_id=${placeOrCoordinates.google_place_id}`;
            }
            window.open(url, "_system");
          },
          setPlaceToCurrentLocation: (containerId) => {
            getCurrentPlace(props.getCurrentLocation, props.showErrorMessage).then((place) => {
              setTransferForm("form", "placeContainers", (prev) =>
                prev.map((c) => (c.containerId === containerId ? { ...c, place } : c))
              );
            });
          },
          showErrorMessage: props.showErrorMessage,
          showTimePicker: (initialTime) => props.showTimePicker([transferForm, setTransferForm], initialTime),
          showDatePicker: (initialDate) => props.showDatePicker([transferForm, setTransferForm], initialDate),
          setPlaceContainer: (containerId, place) =>
            setTransferForm("form", "placeContainers", (prev) =>
              prev.map((c) => (c.containerId === containerId ? { ...c, place } : c))
            ),
          clearForm: () => {
            setTransferForm("form", getInitialFormState());
          },
          addPlaceContainer: (place, index = -1) => {
            const newId = createUniqueId();
            setTransferForm(
              "form",
              "placeContainers",
              produce((array) =>
                array.splice(index < 0 ? array.length + index + 1 : index, 0, {
                  containerId: newId,
                  place,
                })
              )
            );

            return newId;
          },
          getCurrentPlace: () => getCurrentPlace(props.getCurrentLocation, props.showErrorMessage),
        },
      ]}
    >
      {props.children}
    </TransferFormContext.Provider>
  );
};

export function useTransferForm() {
  const context = useContext(TransferFormContext);
  if (!context) throw new Error("You didn't use useTransferForm inside a TransferFormProvider");
  return context;
}

function getCurrentPlace(
  getCurrentLocation: () => Promise<MyCoordinates2D>,
  showErrorMessage: (message: string) => void
) {
  return getCurrentLocation().then(async (coordinates) => {
    let place: Awaited<ReturnType<typeof mooovexApiClient.place.reversegeocode>>;

    try {
      place = await mooovexApiClient.place.reversegeocode(coordinates.toLngLat(), currentLocale());
    } catch (error) {
      captureException(error);
    }

    if (!place) {
      showErrorMessage(ct["place.couldNotFindPlaceName"]());
      place = makeUnknownPlace(coordinates);
    }

    return place;
  });
}
