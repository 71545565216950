import { useSolidMap } from "@mooovex/solid-map";
import PercentIcon from "lucide-solid/icons/percent";
import { Component, Match, Switch } from "solid-js";
import { Portal } from "solid-js/web";
import { ct } from "../i18n";
import { useTransferForm } from "../TransferFormProvider";

type Props = {};

export const RideInfo: Component<Props> = (props) => {
  const map = useSolidMap();
  const [transferForm, setTransferForm] = useTransferForm();

  return (
    <Portal mount={map.getContainer()}>
      <div class="tw-absolute tw-bottom-2 tw-left-2 tw-right-[56px] tw-z-50 tw-flex tw-h-[80px] tw-items-center tw-justify-between tw-text-nowrap tw-rounded-md tw-border tw-bg-background tw-shadow-sm">
        <Switch>
          <Match when={transferForm.form.route}>
            {(route) => (
              <>
                <div class="d-flex flex-column gap-2 overflow-hidden my-3 ms-3">
                  <div class="fw-bold fs-6 text-truncate">{ct.formatters.humanDuration(route().duration * 1000)}</div>
                  <div class="fw-bold fs-6 text-truncate">{ct.formatters.kilometers(route().distance / 1000)}</div>
                </div>
                <button
                  class="btn btn-light position-relative h-100 p-0"
                  onclick={() => setTransferForm("priceOptionsVisible", (prev) => !prev)}
                >
                  <Switch>
                    <Match when={transferForm.form.price !== undefined}>
                      <div class="fw-bold text-primary fs-1 m-3">{ct.formatters.euros(transferForm.form.price!)}</div>
                    </Match>
                    <Match when={transferForm.form.priceLoading}>
                      <div class="spinner-border mx-5" />
                    </Match>
                  </Switch>
                  <PercentIcon
                    color={transferForm.form.discount !== 0 ? "var(--bs-red)" : undefined}
                    class="position-absolute cursor-pointer"
                    style={{ top: "1px", right: "1px" }}
                    size={24}
                  />
                </button>
              </>
            )}
          </Match>
          <Match when={transferForm.form.routeLoading}>
            <div class="d-flex align-items-center gap-2 overflow-hidden my-3 ms-3">
              <span class="spinner-border spinner-border-sm" />
              <span class="fw-bold fs-6 text-truncate">{ct.ride.calculatingRoute()}</span>
            </div>
          </Match>
        </Switch>
      </div>
    </Portal>
  );
};
