import { type ComponentProps, splitProps } from "solid-js";
import { cn } from "../cn";

export const Table = (props: ComponentProps<"table">) => {
  const [local, rest] = splitProps(props, ["class"]);

  return (
    <div class="tw-w-full tw-overflow-auto">
      <table class={cn("tw-w-full tw-caption-bottom tw-text-sm", local.class)} {...rest} />
    </div>
  );
};

export const TableHeader = (props: ComponentProps<"thead">) => {
  const [local, rest] = splitProps(props, ["class"]);

  return <thead class={cn("[&_tr]:tw-border-b", local.class)} {...rest} />;
};

export const TableBody = (props: ComponentProps<"tbody">) => {
  const [local, rest] = splitProps(props, ["class"]);

  return <tbody class={cn("[&_tr:last-child]:tw-border-0", local.class)} {...rest} />;
};

export const TableFooter = (props: ComponentProps<"tfoot">) => {
  const [local, rest] = splitProps(props, ["class"]);

  return <tbody class={cn("tw-bg-primary tw-font-medium tw-text-primary-foreground", local.class)} {...rest} />;
};

export const TableRow = (props: ComponentProps<"tr">) => {
  const [local, rest] = splitProps(props, ["class"]);

  return (
    <tr
      class={cn("tw-border-b tw-transition-colors hover:tw-bg-muted/50 data-[state=selected]:tw-bg-muted", local.class)}
      {...rest}
    />
  );
};

export const TableHead = (props: ComponentProps<"th">) => {
  const [local, rest] = splitProps(props, ["class"]);

  return (
    <th
      class={cn(
        "tw-h-10 tw-text-left tw-align-middle tw-font-medium [&:has([role=checkbox])]:tw-pr-0 [&>[role=checkbox]]:tw-translate-y-[2px]",
        local.class
      )}
      {...rest}
    />
  );
};

export const TableCell = (props: ComponentProps<"td">) => {
  const [local, rest] = splitProps(props, ["class"]);

  return (
    <td
      class={cn(
        "tw-align-middle [&:has([role=checkbox])]:tw-pr-0 [&>[role=checkbox]]:tw-translate-y-[2px]",
        local.class
      )}
      {...rest}
    />
  );
};

export const TableCaption = (props: ComponentProps<"caption">) => {
  const [local, rest] = splitProps(props, ["class"]);

  return <caption class={cn("tw-mt-4 tw-text-sm tw-text-muted-foreground", local.class)} {...rest} />;
};
