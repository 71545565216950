import * as API from "@mooovex/api-schema";
import { createModal, currentLocale } from "@mooovex/components";
import { MyDate, MyTime, MyTimestamp } from "@mooovex/helpers";
import {
  DragDropProvider,
  DragDropSensors,
  DragEventHandler,
  SortableProvider,
  closestCenter,
} from "@thisbeyond/solid-dnd";
import { Component, For, createSignal } from "solid-js";
import { Portal, render } from "solid-js/web";
import { Datepicker } from "../components/Datepicker";
import AddPlaceModal from "../components/pricelist/AddPlaceModal";
import HTMLTable from "../components/pricelist/HTMLTable";
import PlaceTD from "../components/pricelist/PlaceTD";
import { fetchPrice } from "../components/pricelist/RouteTD";
import { TR } from "../components/pricelist/TR";
import {
  destinations,
  origins,
  routes,
  setDestinations,
  setOrigins,
  setPrice,
} from "../services/state/newpricelist.state";
import classes from "./Pricelist.page.module.scss";

export const PricelistPage: Component = () => {
  const [Modal, { show: showModal, hide: hideModal }] = createModal();

  const [startDate, setStartDate] = createSignal<MyDate>();
  const [startTime, setStartTime] = createSignal<MyTime>();

  function download(content: string, fileName: string, contentType: string) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  function exportHTML() {
    const elem = document.createElement("div");
    render(() => <HTMLTable />, elem);
    const str = elem.innerHTML;
    download(str, "preisliste.html", "text/html");
  }

  const originIds = () => origins().map(API.PlaceAdapter.getId);
  const destinationIds = () => destinations().map(API.PlaceAdapter.getId);

  const onOriginDragEnd: DragEventHandler = ({ draggable, droppable }) => {
    if (draggable && droppable) {
      const currentItems = origins();
      const fromIndex = currentItems.findIndex((o) => API.PlaceAdapter.getId(o) === draggable.id);
      const toIndex = currentItems.findIndex((o) => API.PlaceAdapter.getId(o) === droppable.id);
      if (fromIndex !== toIndex) {
        const updatedItems = currentItems.slice();
        updatedItems.splice(toIndex, 0, ...updatedItems.splice(fromIndex, 1));
        setOrigins(updatedItems);
      }
    }
  };

  const onDestinationDragEnd: DragEventHandler = ({ draggable, droppable }) => {
    if (draggable && droppable) {
      const currentItems = destinations();
      const fromIndex = currentItems.findIndex((o) => API.PlaceAdapter.getId(o) === draggable.id);
      const toIndex = currentItems.findIndex((o) => API.PlaceAdapter.getId(o) === droppable.id);
      if (fromIndex !== toIndex) {
        const updatedItems = currentItems.slice();
        updatedItems.splice(toIndex, 0, ...updatedItems.splice(fromIndex, 1));
        setDestinations(updatedItems);
      }
    }
  };

  async function recalculateAllPrices() {
    if (!confirm("Are you sure?")) return;

    const promises = routes().map((r) =>
      fetchPrice(
        r.routeId,
        1,
        0,
        MyTimestamp.fromMyDateAndMyTime(startDate() ?? MyDate.today(), startTime() ?? MyTime.now())
      ).then((result) => {
        if (result) {
          console.log(result);
          setPrice(r.originId, r.destinationId, result.price, result.extraPersonPrice);
        }
      })
    );

    await Promise.all(promises);
  }

  function recalculateAllRoutes() {
    if (!confirm("Are you sure?")) return;
  }

  return (
    <>
      <div class="d-flex flex-column h-100 gap-2">
        <h1>Pricelist</h1>
        <div class="input-group p-2">
          <button class="btn btn-border-primary" onclick={showModal}>
            Add Place
          </button>
          <Datepicker
            locale={currentLocale()}
            placeholder="Date"
            onSelect={(value) => setStartDate(value?.getMyDate())}
            clearButton
            class={classes.datepicker}
            position="bottom left"
          />
          <Datepicker
            locale={currentLocale()}
            timepicker={true}
            datepicker={false}
            placeholder="Time"
            onSelect={(value) => setStartTime(value?.getMyTime())}
            clearButton
            class={classes.datepicker}
            position="bottom left"
          />
          {/* <button class="btn btn-border-primary" onclick={recalculateAllRoutes}>
            Recalculate All Routes
          </button> */}
          <button class="btn btn-border-primary" onclick={recalculateAllPrices}>
            Recalculate All Prices
          </button>
          <button class="btn btn-border-success" onclick={exportHTML}>
            Export HTML
          </button>
        </div>
        <DragDropProvider onDragEnd={onOriginDragEnd} collisionDetector={closestCenter}>
          <DragDropSensors />
          <div class="overflow-hidden flex-grow-1 position-relative">
            <div class="position-absolute top-0 right-0 bottom-0 left-0 overflow-auto w-100">
              <table class="table table-striped">
                <thead class="sticky-top bg-white shadow">
                  <tr>
                    <th>Origins/Destinations</th>
                    <DragDropProvider onDragEnd={onDestinationDragEnd} collisionDetector={closestCenter}>
                      <DragDropSensors />
                      <SortableProvider ids={destinationIds()}>
                        <For each={destinations()}>{(place) => <PlaceTD place={place} type="destination" />}</For>
                      </SortableProvider>
                    </DragDropProvider>
                  </tr>
                </thead>
                <tbody>
                  <SortableProvider ids={originIds()}>
                    <For each={origins()}>
                      {(origin) => <TR origin={origin} dateGetter={startDate} timeGetter={startTime} />}
                    </For>
                  </SortableProvider>
                </tbody>
              </table>
            </div>
          </div>
        </DragDropProvider>
      </div>
      <Portal>
        <Modal align="center" size="sm" scrollable={false}>
          <AddPlaceModal />
        </Modal>
      </Portal>
    </>
  );
};

export default PricelistPage;
