/* @refresh reload */
import "./scss/styles.scss";

import { initSupabase } from "@mooovex/supabase-client";
import * as Sentry from "@sentry/browser";
import { QueryClient, QueryClientProvider } from "@tanstack/solid-query";
import { SolidQueryDevtools } from "@tanstack/solid-query-devtools";
import { DEV } from "solid-js";
import { render } from "solid-js/web";
import App from "./App";

if (!DEV) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
      Sentry.httpClientIntegration(),
    ],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

initSupabase(import.meta.env.VITE_SUPABASE_URL, import.meta.env.VITE_SUPABASE_KEY);

const queryClient = new QueryClient({ defaultOptions: { queries: { retry: 1 }, mutations: { retry: 1 } } });

const dispose = render(
  () => (
    <QueryClientProvider client={queryClient}>
      <App />
      <SolidQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
    </QueryClientProvider>
  ),
  document.getElementById("root") as HTMLElement
);

if (import.meta.hot) {
  import.meta.hot.dispose(dispose);
}

declare module "solid-js" {
  namespace JSX {
    interface CustomEvents {
      "show.bs.dropdown": Event;
      "shown.bs.dropdown": Event;
      "hide.bs.dropdown": Event;
      "hidden.bs.dropdown": Event;
    }
  }
}
