import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-zod";
import logoUrl from "@mooovex/assets/images/mooovex_logo.svg";
import { A } from "@solidjs/router";
import LoaderCircleIcon from "lucide-solid/icons/loader-circle";
import { Component, createSignal, Show } from "solid-js";
import { z } from "zod";
import { ct } from "./i18n";
import * as localized from "./localized_schemas";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader } from "./ui/card";

const getSchema = () =>
  z.object({
    email: localized.email(),
  });

type Schema = z.infer<ReturnType<typeof getSchema>>;

type Props = {
  onSubmit?: (resetData: Schema) => Promise<void>;
  email?: string;
  loginHref: string;
};

export const ResetPasswordForm: Component<Props> = (props) => {
  const [resetStatus, setResetStatus] = createSignal<"ready" | "error" | "loading" | "success">("ready");

  const { form, errors, isValid, data } = createForm<Schema>({
    extend: [validator({ schema: getSchema() })],
    onSubmit: async (values) => {
      setResetStatus("loading");
      try {
        await props.onSubmit?.(values);
        setResetStatus("success");
      } catch (error) {
        console.log(error);
        setResetStatus("error");
      }
    },
    initialValues: { email: props.email },
  });

  return (
    <form ref={form} class="tw-flex tw-grow tw-items-center tw-justify-center tw-p-4">
      <Card class="tw-w-full tw-max-w-md tw-p-4">
        <CardHeader class="tw-flex tw-items-center tw-justify-center tw-pb-4">
          <img src={logoUrl} alt="logo" class="tw-h-auto tw-max-w-[150px]" />
        </CardHeader>
        <CardContent class="tw-flex tw-w-full tw-flex-col">
          <fieldset disabled={resetStatus() === "loading" || resetStatus() === "success"}>
            <div class="form-text mb-3">{ct.account.passwordResetInstructions()}</div>
            <div class="form-floating mb-1">
              <input
                name="email"
                type="email"
                class="form-control"
                classList={{ "is-invalid": !!errors().email?.length }}
                placeholder="name@example.com"
              />
              <label for="email" class="form-label">
                {ct.account.email._()}
              </label>
              <div class="text-danger" classList={{ invisible: !errors().email?.length }}>
                {errors().email?.[0] ?? "-"}
              </div>
            </div>
            <Show when={resetStatus() === "error"}>
              <div class="alert alert-danger">{ct.validation.passwordResetFail()}</div>
            </Show>
            <Show when={resetStatus() !== "success"}>
              <Button size="lg" class="tw-w-full tw-gap-2" block type="submit" disabled={!isValid()}>
                <Show when={resetStatus() === "loading"}>
                  <LoaderCircleIcon class="tw-animate-spin" />
                </Show>
                {ct.account.resetPassword()}
              </Button>
            </Show>
          </fieldset>
          <Show when={resetStatus() === "success"}>
            <div class="alert alert-success">{ct.validation.passwordResetSuccess(data().email)}</div>
          </Show>
          <A href={props.loginHref} class="form-text tw-underline">
            {ct.account.login()}
          </A>
        </CardContent>
      </Card>
    </form>
  );
};

export default ResetPasswordForm;
