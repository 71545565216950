import * as mooovexApiClient from "@mooovex/api-client";
import * as API from "@mooovex/api-schema";
import { captureException } from "@sentry/browser";
import StarIcon from "lucide-solid/icons/star";
import { Component } from "solid-js";
import { ct, currentLocale } from "../i18n";
import { useTransferForm } from "../TransferFormProvider";
import { Button } from "../ui/button";
import { PlaceSearchIcon } from "./placeSearch.common";

type Props = {
  place: API.google_autocomplete.ResponseBodyItem;
  containerId: string;
  isFavorited?: boolean;
  compact?: boolean;
};

async function autocompleteItemToPlace(autocompleteItem: API.google_autocomplete.ResponseBodyItem) {
  const result = await mooovexApiClient.place.get(autocompleteItem.google_place_id, currentLocale());
  result.name = autocompleteItem.name;
  result.formatted_address = autocompleteItem.formatted_address;
  return result;
}

export const PlaceSearchAutocompleteItem: Component<Props> = (props) => {
  const [transferForm, setTransferForm, { showErrorMessage, setPlaceContainer }] = useTransferForm();
  const address = () => API.PlaceAdapter.getAddress(props.place);

  function addToPlaceSearchHistory(place: API.google_autocomplete.ResponseBodyItem) {
    if (transferForm.placeSearchHistory.some((p) => p.google_place_id === place.google_place_id)) return;
    setTransferForm("placeSearchHistory", (prev) => [place, ...prev].slice(0, 5));
  }

  function addToPlaceFavorites(place: API.google_autocomplete.ResponseBodyItem) {
    setTransferForm("placeSearchFavorites", (prev) => [place, ...prev]);
  }

  function removeFromPlaceFavorites(id: number | string) {
    setTransferForm("placeSearchFavorites", (prev) => prev.filter((p) => p.google_place_id !== id));
  }

  return (
    <>
      <div
        class="tw-min-h-10 tw-cursor-pointer"
        onclick={async () => {
          try {
            const place = await autocompleteItemToPlace(props.place);
            setPlaceContainer(props.containerId, place);
            setTransferForm("placeSearchVisible", false);
            addToPlaceSearchHistory(props.place);
          } catch (error) {
            showErrorMessage(ct.place.failedToGetDetails());
            captureException(error);
          }
        }}
      >
        <hr />
        <div class="tw-flex tw-items-center tw-gap-2">
          <PlaceSearchIcon place={props.place} />
          <div
            class="tw-grow"
            classList={{
              "tw-whitespace-nowrap": props.compact,
              "tw-truncate": props.compact,
            }}
          >
            <span class="tw-font-bold">{props.place.name}</span>&nbsp;
            <span class="tw-text-muted-foreground">{address()}</span>
          </div>
          <Button
            variant="ghost"
            size="icon"
            class="tw-shrink-0"
            onclick={(e) => {
              e.stopPropagation();
              if (props.isFavorited) {
                removeFromPlaceFavorites(props.place.google_place_id);
              } else {
                addToPlaceFavorites(props.place);
              }
            }}
          >
            <StarIcon class="tw-text-primary" fill={props.isFavorited ? "currentColor" : "none"} />
          </Button>
        </div>
      </div>
    </>
  );
};
