import * as mooovexApiClient from "@mooovex/api-client";
import * as API from "@mooovex/api-schema";
import { captureException } from "@sentry/browser";
import { debounce } from "@solid-primitives/scheduled";
import HistoryIcon from "lucide-solid/icons/history";
import SearchIcon from "lucide-solid/icons/search";
import StarIcon from "lucide-solid/icons/star";
import TargetIcon from "lucide-solid/icons/target";
import { Component, Show, createEffect, createSignal, onCleanup } from "solid-js";
import { ct, currentLocale } from "../i18n";
import { useTransferForm } from "../TransferFormProvider";
import { PlaceSearchResultList } from "./placeSearch.common";
type Props = {};

export const PlaceSearchOverlay: Component<Props> = (props) => {
  const [transferForm, , { showErrorMessage, closePlaceSearchOverlay, setPlaceToCurrentLocation }] = useTransferForm();

  async function autocomplete(value: string) {
    if (value.length < 3) {
      setPlaces(undefined);
      return;
    }

    try {
      const result = await mooovexApiClient.place.autocomplete(value, currentLocale());
      setPlaces(result);
    } catch (error) {
      setPlaces(undefined);
      showErrorMessage(ct.place.failedToGetPlaces());
      captureException(error);
    }
  }

  const debouncedAutocomplete = debounce(autocomplete, 250);
  const [places, setPlaces] = createSignal<API.google_autocomplete.ResponseBodyItem[]>();

  function onEscapeKey(e: KeyboardEvent) {
    if (e.key === "Escape") {
      closePlaceSearchOverlay();
    }
  }

  onCleanup(() => {
    removeEventListener("keyup", onEscapeKey);
  });

  createEffect(() => {
    if (transferForm.placeSearchVisible) {
      transitionElementRef.addEventListener("transitionend", onTransitionEnd, { once: true });
      addEventListener("keyup", onEscapeKey);
    } else {
      transitionElementRef.removeEventListener("transitionend", onTransitionEnd);
      removeEventListener("keyup", onEscapeKey);
      debouncedAutocomplete.clear();
      setPlaces(undefined);
      if (inputRef) inputRef.value = "";
    }
  });

  function onTransitionEnd() {
    inputRef.focus();
  }

  let inputRef!: HTMLInputElement;
  let transitionElementRef!: HTMLDivElement;

  return (
    <div
      ref={transitionElementRef}
      class="tw-absolute tw-inset-0 tw-z-[70] tw-flex tw-flex-col tw-overflow-hidden tw-bg-white tw-transition-transform"
      classList={{
        "tw-translate-y-full": !transferForm.placeSearchVisible,
      }}
    >
      <Show when={transferForm.form.placeSearchTargetContainerId}>
        {(currentContainerId) => (
          <>
            <div class="input-group tw-p-2 tw-shadow">
              <button class="btn btn-border-dark bi-arrow-left" onclick={closePlaceSearchOverlay} />
              <input
                type="text"
                class="form-control form-control-lg"
                ref={inputRef}
                oninput={(e) => debouncedAutocomplete(e.currentTarget.value)}
              />
            </div>
            <div class="tw-grow tw-overflow-scroll tw-px-2">
              <hr />
              <div
                class="tw-flex tw-h-10 tw-cursor-pointer tw-items-center tw-gap-2"
                onclick={() => {
                  setPlaceToCurrentLocation(currentContainerId());
                  closePlaceSearchOverlay();
                }}
              >
                <TargetIcon size={16} />
                <span class="fw-bold">{ct.ride.currentLocation()}</span>
              </div>
              <hr />
              <Show
                when={places()}
                fallback={
                  <>
                    <Show when={transferForm.placeSearchFavorites.length}>
                      <div class="tw-flex tw-h-8 tw-items-center tw-justify-center tw-gap-2 tw-text-center tw-text-sm tw-leading-none tw-text-muted-foreground">
                        <StarIcon size={16} fill="currentColor" />
                        {ct.place.search.favorites()}
                      </div>
                      <PlaceSearchResultList
                        places={transferForm.placeSearchFavorites}
                        containerId={currentContainerId()}
                        favorites={transferForm.placeSearchFavorites}
                        compact
                      />
                      <hr />
                    </Show>
                    <Show when={transferForm.placeSearchHistory.length}>
                      <div class="tw-flex tw-h-8 tw-items-center tw-justify-center tw-gap-2 tw-text-center tw-text-sm tw-leading-none tw-text-muted-foreground">
                        <HistoryIcon size={16} />
                        {ct.place.search.history()}
                      </div>
                      <PlaceSearchResultList
                        places={transferForm.placeSearchHistory}
                        containerId={currentContainerId()}
                        favorites={transferForm.placeSearchFavorites}
                        compact
                      />
                      <hr />
                    </Show>
                  </>
                }
              >
                {(places) => (
                  <>
                    <div class="tw-flex tw-h-8 tw-items-center tw-justify-center tw-gap-2 tw-text-center tw-text-sm tw-leading-none tw-text-muted-foreground">
                      <SearchIcon size={16} />
                      <Show when={places().length} fallback={ct.common.noResults()}>
                        {ct.place.search.results()}
                      </Show>
                    </div>
                    <PlaceSearchResultList
                      places={places()}
                      containerId={currentContainerId()}
                      favorites={transferForm.placeSearchFavorites}
                    />
                    <hr />
                  </>
                )}
              </Show>
            </div>
          </>
        )}
      </Show>
    </div>
  );
};
