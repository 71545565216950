import Resizable from "@corvu/resizable";
import * as mooovexApiClient from "@mooovex/api-client";
import * as API from "@mooovex/api-schema";
import {
  NewMap,
  NewPlaceSearch,
  RideForm,
  ct,
  currentLocale,
  useDriverlocations,
  useTaxistations,
  useTransferForm,
} from "@mooovex/components";
import { MyDate, MyTime, MyTimestamp } from "@mooovex/helpers";
import { currentRoles } from "@mooovex/supabase-client";
import { captureException } from "@sentry/browser";
import { Key } from "@solid-primitives/keyed";
import { debounce } from "@solid-primitives/scheduled";
import { makePersisted } from "@solid-primitives/storage";
import GripVerticalIcon from "lucide-solid/icons/grip-vertical";
import { Component, For, Show, createEffect, createSignal, on, onCleanup, onMount } from "solid-js";
import { ClientDriverlist, ClientDriverlistZipcodeOrderButton } from "../components/ClientDriverlist";
import { Datepicker } from "../components/Datepicker";
import { MapHideFormControl } from "../components/MapHideFormControl";
import { TaxistationsC } from "../components/Taxistations.c";
import TransportcompanySelectorC from "../components/TransportcompanySelector.c";
import { selectedTransportcompanyId } from "../services/state/transportcompanies.state";

const DriversPage: Component = () => {
  const [transferForm, setTransferForm, { placesInWaypoints, showErrorMessage }] = useTransferForm();
  const {
    driverlocationsArray,
    startPolling: startPollingDriverlocations,
    stopPolling: stopPollingDriverlocations,
  } = useDriverlocations();
  const { startPolling: startPollingTaxistations, stopPolling: stopPollingTaxistations } = useTaxistations();

  onMount(async () => {
    startPollingDriverlocations();
    startPollingTaxistations();
  });

  onCleanup(async () => {
    stopPollingDriverlocations();
    stopPollingTaxistations();
  });

  const debouncedRouteCalculation = debounce(async () => {
    if (placesInWaypoints().length >= 2) {
      setTransferForm("form", "route", undefined);

      const date = transferForm.form.startDate;
      const time = transferForm.form.startTime;
      const timestamp = MyTimestamp.fromMyDateAndMyTime(date ?? MyDate.today(), time ?? MyTime.now());

      try {
        setTransferForm("form", "routeLoading", true);
        const route = await mooovexApiClient.route.compute(
          placesInWaypoints().map(API.PlaceAdapter.getCoordinates),
          timestamp,
          selectedTransportcompanyId()
        );
        setTransferForm("form", "route", route);
      } catch (error) {
        captureException(error);
        showErrorMessage(ct.ride.route.calculation.error());
      } finally {
        setTransferForm("form", "routeLoading", false);
      }
    } else {
      setTransferForm("form", "route", undefined);
    }
  }, 100);

  const debouncedPriceCalculation = debounce(async () => {
    if (transferForm.form.route && transferForm.form.passengerCount !== undefined) {
      setTransferForm("form", "price", undefined);
      const date = transferForm.form.startDate;
      const time = transferForm.form.startTime;
      const timestamp = MyTimestamp.fromMyDateAndMyTime(date ?? MyDate.today(), time ?? MyTime.now());

      try {
        setTransferForm("form", "priceLoading", true);
        const price = await mooovexApiClient.price.compute({
          routeId: transferForm.form.route.id,
          passengerCount: transferForm.form.passengerCount,
          discount: transferForm.form.discount,
          timestamp,
          transportcompanyId: selectedTransportcompanyId(),
        });
        setTransferForm("form", "price", price.price);
      } catch (error) {
        captureException(error);
        showErrorMessage(ct.price.calculation.error());
        setTransferForm("form", "price", undefined);
      } finally {
        setTransferForm("form", "priceLoading", false);
      }
    } else {
      setTransferForm("form", "price", undefined);
    }
  }, 300);

  // Route Calclculation
  createEffect(on(placesInWaypoints, debouncedRouteCalculation));

  // Price calculation
  createEffect(
    on(
      [
        () => transferForm.form.route,
        () => transferForm.form.passengerCount,
        () => transferForm.form.startDate,
        () => transferForm.form.startTime,
        () => transferForm.form.discount,
        selectedTransportcompanyId,
      ],
      debouncedPriceCalculation
    )
  );

  const [resizablePanelSizes, setResizablePanelSizes] = makePersisted(createSignal<number[]>([]), {
    name: "mooovex:driversPagePanelSizes",
  });

  const [isSidebarCollapsed, setIsSidebarCollapsed] = createSignal(false);

  return (
    <>
      <Resizable sizes={resizablePanelSizes()} onSizesChange={setResizablePanelSizes} class="h-100 overflow-x-hidden">
        <Resizable.Panel initialSize={0.85}>
          <div class="d-flex flex-column h-100 overflow-hidden">
            <NewMap.MapComponent class="flex-grow-1">
              <NewMap.MapAnimations right={56} left={56} bottom={96} />
              <NewMap.MapControlGroup bottom={8} right={8}>
                <MapHideFormControl />
                <NewMap.MapZoomControl />
              </NewMap.MapControlGroup>
              <Show when={transferForm.form.route || transferForm.form.routeLoading}>
                <RideForm.RideInfo />
              </Show>
              <NewMap.MapTaxistations />
              <Key each={driverlocationsArray()} by={(driverlocation) => driverlocation.driverId}>
                {(driverlocation) => (
                  <NewMap.MapDriverMarker
                    driverlocation={driverlocation()}
                    follow={driverlocation().driverId === transferForm.centeredDriverId}
                  />
                )}
              </Key>
              <For each={placesInWaypoints()}>
                {(place, i) => (
                  <NewMap.MapPlaceMarker
                    lngLat={API.PlaceAdapter.getCoordinates(place)}
                    index={i()}
                    count={placesInWaypoints().length}
                  />
                )}
              </For>
              <Show when={transferForm.form.route}>
                {(route) => (
                  <>
                    <NewMap.MapLineString linestring={route().linestring} />
                    <Show when={route().return_route}>
                      {(return_route) => (
                        <NewMap.MapLineString linestring={return_route().linestring} style="return_route" />
                      )}
                    </Show>
                  </>
                )}
              </Show>
            </NewMap.MapComponent>
            <Show when={transferForm.formVisible}>
              <div class="d-flex flex-column gap-1 p-1 position-relative">
                <RideForm.DragAndDropPlaces />
                <div class="d-flex gap-2">
                  <RideForm.PlaceAddButton maxPlaceCount={5} />
                  <RideForm.PassengerCountButton />
                  <Datepicker
                    locale={currentLocale()}
                    clearButton
                    placeholder={ct.common.date()}
                    datepicker={true}
                    timepicker={false}
                    position="top left"
                    onSelect={(timestamp) => setTransferForm("form", "startDate", timestamp?.getMyDate())}
                    value={transferForm.form.startDate?.getMyTimestamp()}
                  />
                  <Datepicker
                    locale={currentLocale()}
                    clearButton
                    placeholder={ct.common.time()}
                    datepicker={false}
                    timepicker={true}
                    position="top left"
                    onSelect={(timestamp) => setTransferForm("form", "startTime", timestamp?.getMyTime())}
                    value={transferForm.form.startTime?.getMyTimestamp()}
                  />
                  <RideForm.ClearRideFormButton />
                </div>
                <RideForm.PassengerCountSelectorOverlay min={1} max={10} />
                <RideForm.SimplePriceOptionsOverlay />
              </div>
            </Show>
          </div>
          <NewPlaceSearch.PlaceSearchOverlay />
        </Resizable.Panel>
        <Resizable.Handle
          class="d-flex justify-content-center align-items-center"
          style={{
            margin: 0,
            padding: 0,
            background: "white",
            border: "none",
          }}
        >
          <GripVerticalIcon size={12} />
        </Resizable.Handle>
        <Resizable.Panel
          initialSize={0.15}
          collapsible
          minSize={0.1}
          onCollapse={() => setIsSidebarCollapsed(true)}
          onExpand={() => setIsSidebarCollapsed(false)}
          class="overflow-hidden"
        >
          <Show when={!isSidebarCollapsed()}>
            <div class="p-2 ps-0 h-100 overflow-y-auto overflow-x-hidden bg-white flex-shrink-0">
              <TransportcompanySelectorC />
              <Show when={currentRoles().some((role) => ["taxistations", "owner"].includes(role))}>
                <TaxistationsC />
              </Show>
              <ClientDriverlistZipcodeOrderButton />
              <ClientDriverlist
                onDriverClick={(driver_id) => {
                  setTransferForm("centeredDriverId", driver_id);
                  NewMap.driverMarkerInstances.forEach((marker) => marker.getPopup()?.remove());
                  const driverMarkerInstance = NewMap.driverMarkerInstances.get(driver_id);
                  if (driverMarkerInstance) {
                    driverMarkerInstance.getPopup()?.addTo(driverMarkerInstance._map);
                  }
                }}
              />
            </div>
          </Show>
        </Resizable.Panel>
      </Resizable>
    </>
  );
};

export default DriversPage;
