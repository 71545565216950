import { Shared } from "@mooovex/api-schema";
import { ApiClientError, client } from "./client";

export async function autocomplete(value: string, locale: Shared.Locale) {
  const response = await client.v1.place.autocomplete.$post({
    json: {
      search_text: value,
      language: locale,
    },
  });

  if (!response.ok) {
    throw new ApiClientError("Place Autocomplete failed", response);
  }

  return await response.json();
}

export async function get(googlePlaceId: string, locale: Shared.Locale) {
  const response = await client.v1.place.get.$post({
    json: {
      google_place_id: googlePlaceId,
      language: locale,
    },
  });

  if (!response.ok) {
    throw new ApiClientError("Place Get failed", response);
  }

  return await response.json();
}

export async function reversegeocode(lngLat: Shared.GeoJson.Coordinates, locale: Shared.Locale) {
  const response = await client.v1.place.reversegeocode.$post({
    json: {
      coordinates: lngLat,
      language: locale,
    },
  });

  if (!response.ok) {
    if (response.status === 404) {
      return undefined;
    } else {
      throw new ApiClientError("Place Reversegeocode failed", response);
    }
  }

  return await response.json();
}
