import { Tables } from "@mooovex/supabase";
import { ReactiveMap } from "@solid-primitives/map";
import { batch, createSelector, createSignal } from "solid-js";
import { supabase, userSignal } from ".";

export const vehiclesCache = new ReactiveMap<number, Tables<"vehicles">>();

export async function fetchVehicleById(id: number) {
  const { data, error } = await supabase.from("vehicles").select().eq("id", id).maybeSingle();

  if (error) throw error;

  if (data) {
    vehiclesCache.set(data.id, data);
  } else {
    vehiclesCache.delete(id);
  }

  return data;
}

export async function fetchVehicleByIdBatch(ids: number[]) {
  const { data, error } = await supabase.from("vehicles").select().in("id", ids);

  if (error) throw error;

  batch(() => {
    for (const vehicle of data) {
      vehiclesCache.set(vehicle.id, vehicle);
    }
  });
  return data;
}

export async function fetchAvailableVehicles(transportcompanyId: number) {
  const user = userSignal();
  if (!user) throw new Error("User not logged in");
  const { data, error } = await supabase
    .from("vehicles")
    .select(`*, drivers_vehicles!inner(driver_id)`)
    .eq("transportcompany_id", transportcompanyId)
    .eq("drivers_vehicles.driver_id", user.id)
    .order("identifier");
  if (error) throw error;

  for (const vehicle of data) {
    vehiclesCache.set(vehicle.id, vehicle);
  }

  setAvailableVehicleIds(data.map((v) => v.id));

  return data;
}

export async function fetchVehiclesOfTransportcompany(transportcompanyId: number) {
  const { data, error } = await supabase
    .from("vehicles")
    .select(`*`)
    .eq("transportcompany_id", transportcompanyId)
    .order("identifier");
  if (error) throw error;

  for (const vehicle of data) {
    vehiclesCache.set(vehicle.id, vehicle);
  }

  return data;
}

export async function fetchCurrentVehicle() {
  const user = userSignal();
  if (!user) throw new Error("User not logged in");
  const { data, error } = await supabase
    .from("drivers")
    .select("current_vehicle:vehicles!drivers_current_vehicle_id_fkey(*)")
    .eq("staff_id", user.id)
    .single();
  if (error) throw error;

  setCurrentVehicleId(data.current_vehicle?.id);

  if (data.current_vehicle) {
    vehiclesCache.set(data.current_vehicle.id, data.current_vehicle);
  }

  return data.current_vehicle;
}

export const [availableVehicleIds, setAvailableVehicleIds] = createSignal<number[]>([]);

export const [currentVehicleId, setCurrentVehicleId] = createSignal<number>();

export const availableVehicles = () =>
  availableVehicleIds().flatMap((id) => (vehiclesCache.has(id) ? [vehiclesCache.get(id)!] : []));

export const currentVehicle = () => vehiclesCache.get(currentVehicleId()!);

export const isCurrentVehicle = createSelector(currentVehicle, (a, b) => !!a && !!b && a.id === b.id);
