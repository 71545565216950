import { ct, LocaleSelectorDropdown } from "@mooovex/components";
import { Button } from "@mooovex/components/ui/button";
import { myStaff, signOut } from "@mooovex/supabase-client";
import { A } from "@solidjs/router";
import LogOutIcon from "lucide-solid/icons/log-out";
import SettingsIcon from "lucide-solid/icons/settings";
import { Component } from "solid-js";

type Props = {
  onLogout?: () => Promise<boolean>;
};

const UserInfoC: Component<Props> = (props) => {
  return (
    <>
      <div class="tw-flex tw-items-center tw-justify-end tw-gap-2">
        <Button variant="outline" as={A} href="/user">
          <SettingsIcon class="tw-mr-1" size={16} />
          {myStaff()?.first_name} {myStaff()?.last_name}
        </Button>
        <LocaleSelectorDropdown />
        <Button
          onclick={async () => {
            if (!props.onLogout || (await props.onLogout())) {
              await signOut();
            }
          }}
        >
          <LogOutIcon class="tw-mr-1" size={16} />
          {ct.account.logout()}
        </Button>
      </div>
    </>
  );
};

export default UserInfoC;
