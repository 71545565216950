import * as mooovexApiClient from "@mooovex/api-client";
import { makePersisted } from "@solid-primitives/storage";
import { createContext, ParentComponent, useContext } from "solid-js";
import { createStore, reconcile, SetStoreFunction } from "solid-js/store";

export type Taxistation = mooovexApiClient.InferResponseType<
  typeof mooovexApiClient.bearerClient.v2.taxistations.$get,
  200
>[number];

type TaxistationsStoreType = Record<string, Taxistation>;
const [taxistations, setTaxistations] = makePersisted(createStore<TaxistationsStoreType>({}), {
  name: "mooovex:taxistations",
});

type Context = {
  taxistations: TaxistationsStoreType;
  setTaxistations: SetStoreFunction<TaxistationsStoreType>;
  taxistationsArray: () => Taxistation[];
  startPolling: () => void;
  stopPolling: () => void;
  setActiveTaxistationRequest: (taxistationId: number) => Promise<void>;
  setEnabledTaxistationRequest: (taxistationId: number, enabled: boolean) => Promise<void>;
  setEnabledTaxistationZipcodeRequest: (zipcodeId: number, enabled: boolean) => Promise<void>;
};

export const TaxistationsContext = createContext<Context>();

type Props = {
  showErrorMessage: (message: string) => void;
  asTransportcompanyId?: number;
};

let pollingInterval: ReturnType<typeof setInterval> | undefined;

export const TaxistationsProvider: ParentComponent<Props> = (props) => {
  return (
    <TaxistationsContext.Provider
      value={{
        taxistations: taxistations,
        setTaxistations: setTaxistations,
        taxistationsArray: () => Object.values(taxistations),
        startPolling: () => {
          if (pollingInterval === undefined) {
            fetchTaxistations();
            pollingInterval = setInterval(fetchTaxistations, 30000);
          }
        },
        stopPolling: () => {
          if (pollingInterval !== undefined) {
            clearInterval(pollingInterval);
            pollingInterval = undefined;
          }
        },
        setActiveTaxistationRequest: async (taxistationId) => {
          if (!props.asTransportcompanyId) {
            return;
          }

          // TODO
          await mooovexApiClient.bearerClient.v2.taxistations[":taxistationId"].setactive.$post({
            param: {
              taxistationId: taxistationId.toString(),
            },
            query: {
              asTransportcompanyId: props.asTransportcompanyId.toString(),
            },
          });

          fetchTaxistations();
        },
        setEnabledTaxistationRequest: async (taxistationId, enabled) => {
          if (!props.asTransportcompanyId) {
            return;
          }

          // TODO
          await mooovexApiClient.bearerClient.v2.taxistations[":taxistationId"].setenabled.$post({
            param: {
              taxistationId: taxistationId.toString(),
            },
            query: {
              asTransportcompanyId: props.asTransportcompanyId.toString(),
              enabled: enabled ? "true" : "false", // TODO put in body
            },
          });

          fetchTaxistations();
        },
        setEnabledTaxistationZipcodeRequest: async (zipcodeId, enabled) => {
          if (!props.asTransportcompanyId) {
            return;
          }

          // TODO
          await mooovexApiClient.bearerClient.v2.taxistations.zipcode[":zipcodeId"].setenabled.$post({
            param: {
              zipcodeId: zipcodeId.toString(),
            },
            query: {
              asTransportcompanyId: props.asTransportcompanyId.toString(),
              enabled: enabled ? "true" : "false", // TODO put in body
            },
          });

          fetchTaxistations();
        },
      }}
    >
      {props.children}
    </TaxistationsContext.Provider>
  );

  function fetchTaxistations() {
    mooovexApiClient.bearerClient.v2.taxistations
      .$get()
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            const reduced = data.reduce<TaxistationsStoreType>((prev, curr) => {
              prev[curr.id] = curr;
              return prev;
            }, {});

            setTaxistations(reconcile(reduced));
          });
        } else {
          console.error(new Error("Failed to fetch taxistations", { cause: response }));
        }
      })
      .catch((e) => {
        console.error(new Error("Failed to fetch taxistations", { cause: e }));
      });
  }
};

export function useTaxistations() {
  const context = useContext(TaxistationsContext);
  if (!context) throw new Error("You didn't use useTaxistations inside a TaxistationsProvider");
  return context;
}
