import * as API from "@mooovex/api-schema";
import { createSortable, transformStyle, useDragDropContext } from "@thisbeyond/solid-dnd";
import NavigationIcon from "lucide-solid/icons/navigation";
import XIcon from "lucide-solid/icons/x";
import { Component, Show } from "solid-js";
import { useTransferForm } from "../TransferFormProvider";
import classes from "./PlaceSearchContainer.module.scss";
import { PlaceSearchIcon } from "./PlaceSearchIcon";

type Props = {
  containerId: string;
  place?: API.google_place_details.ResponseBody;
  disabled?: boolean;
  placeholder?: string;
  loading?: boolean;
};

export const PlaceSearchContainer: Component<Props> = (props) => {
  const address = () => props.place && API.PlaceAdapter.getAddress(props.place);
  const sortable = createSortable(props.containerId);
  const [state] = useDragDropContext()!;
  const [transferForm, setTransferForm, { openPlaceSearchOverlay, openPlaceInGoogleMaps, setPlaceContainer }] =
    useTransferForm();

  const sortedPlaceContainers = () => transferForm.form.placeContainers.filter((c) => c.place);
  const myIndex = () => sortedPlaceContainers().findIndex((c) => c.containerId === props.containerId);
  return (
    <>
      <div
        ref={sortable.ref}
        style={{ "touch-action": "none", ...transformStyle(sortable.transform) }}
        class="input-group flex-nowrap bg-light"
        onclick={() => openPlaceSearchOverlay(props.containerId)}
        classList={{
          "grayed-out": props.disabled,
          invisible: sortable.isActiveDraggable,
          [classes.transition]: !!state.active.draggable,
        }}
      >
        <div {...sortable.dragActivators} class={classes.draghandle}>
          <PlaceSearchIcon
            loading={props.loading}
            place={props.place}
            color={
              props.place &&
              (myIndex() === 0 ? "#2b71b7" : myIndex() === sortedPlaceContainers().length - 1 ? "#23bb4c" : undefined)
            }
          />
        </div>

        <div
          class={classes.placeinput}
          classList={{ [classes.empty]: !(props.place || transferForm.form.placeContainers.length > 2) }}
        >
          <Show when={props.place} fallback={<span class="text-muted">{props.placeholder}</span>}>
            {(place) => (
              <>
                <span class="fw-bold">{place().name}</span>&nbsp;<span class="text-muted">{address()}</span>
              </>
            )}
          </Show>
        </div>

        <Show when={props.place || transferForm.form.placeContainers.length > 2}>
          <div class={classes.x}>
            <XIcon
              size={20}
              class="cursor-pointer"
              onclick={(e) => {
                e.stopPropagation();
                if (transferForm.form.placeContainers.length > 2) {
                  setTransferForm("form", "placeContainers", (prev) =>
                    prev.filter((c) => c.containerId !== props.containerId)
                  );
                } else {
                  setPlaceContainer(props.containerId, undefined);
                }
              }}
            />
            <Show when={props.place}>
              {(place) => (
                <NavigationIcon
                  size={20}
                  class="cursor-pointer"
                  onclick={(e) => {
                    e.stopPropagation();
                    openPlaceInGoogleMaps(place());
                  }}
                />
              )}
            </Show>
          </div>
        </Show>
      </div>
    </>
  );
};
