import { ct, IdentifierBadge, useDriverlocations } from "@mooovex/components";
import { Button } from "@mooovex/components/ui/button";
import { Table, TableCell, TableHead, TableHeader, TableRow } from "@mooovex/components/ui/table";
import { makePersisted } from "@solid-primitives/storage";
import ArrowDown01Icon from "lucide-solid/icons/arrow-down-0-1";
import ArrowDown10Icon from "lucide-solid/icons/arrow-down-1-0";
import ChevronDownIcon from "lucide-solid/icons/chevron-down";
import ChevronRightIcon from "lucide-solid/icons/chevron-right";
import PhoneIcon from "lucide-solid/icons/phone";
import { Component, createSignal, For, Show } from "solid-js";
import { Dynamic } from "solid-js/web";

type Props = {
  onDriverClick?: (driverId: string) => void;
};

const [zipcodeOrder, setZipcodeOrder] = makePersisted(createSignal<"asc" | "desc">("asc"), {
  name: "mooovex:driverlistZipcodeOrder",
});

export const ClientDriverlist: Component<Props> = (props) => {
  const { driverlocationsArray } = useDriverlocations();
  const [collapsedZipcodeIds, setCollapsedZipcodeIds] = makePersisted(createSignal<number[]>([]), {
    name: "mooovex:driverlistCollapsedZipcodeIds",
  });

  const zipcodes = () => {
    const zipcodesSet = new Set<number>();
    const zipcodesArray: Array<{ zipcodeId: number; zipcode: string; municipiality: string }> = [];

    for (const driverlocation of driverlocationsArray()) {
      if (!zipcodesSet.has(driverlocation.vehicleZipcodeId)) {
        zipcodesSet.add(driverlocation.vehicleZipcodeId);
        zipcodesArray.push({
          zipcodeId: driverlocation.vehicleZipcodeId,
          zipcode: driverlocation.vehicleZipcode,
          municipiality: driverlocation.vehicleMunicipiality,
        });
      }
    }

    zipcodesArray.sort((a, b) => parseInt(a.zipcode) - parseInt(b.zipcode));
    if (zipcodeOrder() === "desc") {
      zipcodesArray.reverse();
    }

    return zipcodesArray;
  };

  return (
    <>
      <Table>
        <For each={zipcodes()} fallback={<div class="text-center">{ct.driverlist.noDrivers()}</div>}>
          {(zipcode) => {
            const zipcodeDrivers = () =>
              driverlocationsArray()
                .filter((driverlocation) => driverlocation.vehicleZipcodeId === zipcode.zipcodeId)
                .sort((a, b) => parseInt(a.vehicleIdentifier) - parseInt(b.vehicleIdentifier));

            const isZipcodeCollapsed = () => collapsedZipcodeIds().includes(zipcode.zipcodeId);

            function toggleZipcodeCollapsed() {
              if (isZipcodeCollapsed()) {
                setCollapsedZipcodeIds((collapsedZipcodeIds) =>
                  collapsedZipcodeIds.filter((id) => id !== zipcode.zipcodeId)
                );
              } else {
                setCollapsedZipcodeIds((collapsedZipcodeIds) => [...collapsedZipcodeIds, zipcode.zipcodeId]);
              }
            }

            return (
              <>
                <TableHeader>
                  <TableRow onclick={toggleZipcodeCollapsed} class="tw-cursor-pointer tw-select-none">
                    <TableHead class="!tw-h-auto tw-w-[20px]">
                      <Dynamic size={20} component={isZipcodeCollapsed() ? ChevronRightIcon : ChevronDownIcon} />
                    </TableHead>
                    <TableHead
                      colspan={2}
                      class="!tw-h-auto tw-w-full tw-max-w-0 tw-truncate tw-text-xs tw-font-bold"
                      title={zipcode.municipiality}
                    >
                      {zipcode.municipiality}
                    </TableHead>
                    <TableHead class="!tw-h-auto tw-text-center tw-font-normal">({zipcodeDrivers().length})</TableHead>
                  </TableRow>
                </TableHeader>
                <Show when={!isZipcodeCollapsed()}>
                  <tbody>
                    <For each={zipcodeDrivers()}>
                      {(driver) => {
                        return (
                          <>
                            <TableRow
                              class="tw-cursor-pointer tw-align-middle"
                              onclick={() => props.onDriverClick?.(driver.driverId)}
                            >
                              <TableCell>
                                <IdentifierBadge size="sm" status={driver.status}>
                                  {driver.vehicleIdentifier}
                                </IdentifierBadge>
                              </TableCell>
                              <TableCell class="tw-w-full tw-max-w-0 tw-truncate tw-px-2" title={driver.driverName}>
                                {driver.driverName}
                              </TableCell>
                              <TableCell class="tw-text-end">{driver.vehicleSeats}</TableCell>
                              <TableCell class="tw-w-[20px]">
                                <Show when={driver.phoneNumber}>
                                  {(phoneNumber) => (
                                    <Button
                                      class="!tw-size-5 !tw-p-0 tw-align-middle tw-text-primary"
                                      variant="ghost"
                                      title={`${driver.phoneNumber} (${ct["common.rightClickToCopy"]()})`}
                                      onclick={(e) => {
                                        e.stopPropagation();
                                        window.open(`tel:${driver.phoneNumber}`);
                                      }}
                                      oncontextmenu={async (e) => {
                                        e.preventDefault();
                                        await navigator.clipboard.writeText(phoneNumber());
                                      }}
                                    >
                                      <PhoneIcon size={20} />
                                    </Button>
                                  )}
                                </Show>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      }}
                    </For>
                  </tbody>
                </Show>
              </>
            );
          }}
        </For>
      </Table>
    </>
  );
};

export function ClientDriverlistZipcodeOrderButton() {
  return (
    <>
      <Button
        size="sm"
        variant="outline"
        class="tw-w-full tw-gap-2"
        onclick={() => setZipcodeOrder((prev) => (prev === "asc" ? "desc" : "asc"))}
      >
        <span class="tw-truncate">{ct[zipcodeOrder() === "asc" ? "order.ascending" : "order.descending"]()}</span>
        <Dynamic
          class="tw-shrink-0"
          size={20}
          component={zipcodeOrder() === "asc" ? ArrowDown01Icon : ArrowDown10Icon}
        />
      </Button>
    </>
  );
}
