import * as API from "@mooovex/api-schema";
import { SolidMapLayer, SolidMapSource } from "@mooovex/solid-map";
import { Component } from "solid-js";
import { useTaxistations } from "../TaxistationsProvider";

type Props = {};

export const MapTaxistations: Component<Props> = (props) => {
  const { taxistationsArray } = useTaxistations();

  const features = () =>
    taxistationsArray()
      .filter((station) => station.enabled)
      .map(
        (station) =>
          ({
            type: "Feature",
            geometry: API.Shared.GeoJson.makePoint(station.location),
            properties: {
              enabled: station.enabled,
              color: station.active ? "#002672" : "gray",
              circleOpacity: station.active ? 0.2 : 0.1,
              textOpacity: station.active ? 1 : 0.6,
              name: station.name,
            },
          }) satisfies API.Shared.GeoJson.Feature
      );

  return (
    <>
      <SolidMapSource
        sourceSpecification={{ type: "geojson", data: { type: "FeatureCollection", features: features() } }}
      >
        <SolidMapLayer
          layerSpecification={{
            type: "circle",
            paint: {
              "circle-radius": 15,
              "circle-color": ["get", "color"],
              "circle-opacity": ["get", "circleOpacity"],
              "circle-stroke-width": 1,
              "circle-stroke-color": ["get", "color"],
              "circle-stroke-opacity": 1,
              "circle-pitch-alignment": "map",
              "circle-pitch-scale": "map",
            },
            minzoom: 13,
          }}
        />
        <SolidMapLayer
          layerSpecification={{
            type: "symbol",
            filter: ["!=", "true", "disabled"],
            paint: {
              "text-color": "black",
              "text-halo-color": "white",
              "text-halo-width": 1,
              "text-opacity": ["get", "textOpacity"],
            },
            layout: {
              "text-field": ["get", "name"],
              "text-size": 12,
            },
            minzoom: 13,
          }}
        />
      </SolidMapSource>
    </>
  );
};
