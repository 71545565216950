import { createSignal } from "solid-js";
import { myStaff, supabase, userSignal } from ".";

export const [myDevice, setMyDevice] = createSignal<DeviceInfo | undefined>();

type DeviceInfo = {
  id: string;
  name?: string;
  model: string;
  platform: string;
  manufacturer: string;
  os_version: string;
  fcm_token?: string;
};

export async function freeCurrentDeviceRequest(device_id: string) {
  const user = userSignal();
  if (!user) throw new Error("User not logged in");
  await supabase
    .from("drivers")
    .update({ current_device_id: null })
    .eq("staff_id", user.id)
    .eq("current_device_id", device_id)
    .throwOnError();
}

export async function fetchCurrentDevice(): Promise<DeviceInfo | undefined> {
  const staff = myStaff();
  if (!staff) throw new Error("No Staff");
  const { data } = await supabase
    .from("drivers")
    .select("current_device:devices(*)")
    .eq("staff_id", staff.user_id)
    .limit(1)
    .maybeSingle()
    .throwOnError();

  const ret = data?.current_device
    ? {
        id: data.current_device.id,
        fcm_token: data.current_device.fcm_token ?? undefined,
        manufacturer: data.current_device.manufacturer,
        model: data.current_device.model,
        name: data.current_device.name ?? undefined,
        os_version: data.current_device.os_version,
        platform: data.current_device.platform,
      }
    : undefined;

  return ret;
}

export async function overrideCurrentDevice(deviceInfo: DeviceInfo) {
  await supabase
    .rpc("override_current_device", {
      _id: deviceInfo.id,
      _fcm_token: deviceInfo.fcm_token,
      _manufacturer: deviceInfo.manufacturer,
      _model: deviceInfo.model,
      _name: deviceInfo.name,
      _os_version: deviceInfo.os_version,
      _platform: deviceInfo.platform,
    })
    .throwOnError();
}
