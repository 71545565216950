import { MyTimestamp } from "@mooovex/helpers";
import { createTimeDifferenceFromNow } from "@solid-primitives/date";
import PhoneIcon from "lucide-solid/icons/phone";
import { Component, Show } from "solid-js";
import { Driverlocation } from "../DriverlocationsProvider";
import { ct } from "../i18n";
import { Button } from "../ui/button";
import { IdentifierBadge, LicensePlate } from "../vehicles";

type Props = {
  driverlocation: Driverlocation;
};

export const MapDriverMarkerPopup: Component<Props> = (props) => {
  const formattedSpeed = () => ct.formatters.kmh(props.driverlocation.speed * 3.6);
  const timestampMs = () =>
    props.driverlocation.timestamp
      ? MyTimestamp.fromJSON(props.driverlocation.timestamp).toDate().valueOf()
      : Date.now();
  const [timestampDiffMs] = createTimeDifferenceFromNow(timestampMs, 1000);
  const formattedRelativeTime = () => ct.formatters.relativeTime(timestampDiffMs());

  return (
    <div class="tw-flex tw-flex-col tw-gap-1 tw-text-base tw-leading-tight">
      <div class="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-whitespace-nowrap">
        <IdentifierBadge status={props.driverlocation.status}>{props.driverlocation.vehicleIdentifier}</IdentifierBadge>
        <div class="tw-flex tw-min-w-0 tw-flex-col">
          <div class="tw-truncate tw-font-bold">{props.driverlocation.driverName}</div>
          <div class="tw-truncate tw-text-muted-foreground">{props.driverlocation.transportcompanyName}</div>
        </div>
        <Show when={props.driverlocation.phoneNumber}>
          {(phoneNumber) => (
            <Button
              class="tw-shrink-0"
              size="icon"
              variant="outline"
              title={`${phoneNumber()} (${ct["common.rightClickToCopy"]()})`}
              onclick={() => window.open(`tel:${phoneNumber()}`)}
              oncontextmenu={async (e) => {
                e.preventDefault();
                await navigator.clipboard.writeText(phoneNumber());
              }}
            >
              <PhoneIcon />
            </Button>
          )}
        </Show>
      </div>
      <div class="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-whitespace-nowrap">
        <div class="tw-truncate">{props.driverlocation.vehicleName}</div>
        <LicensePlate value={props.driverlocation.vehicleLicensePlate ?? undefined} />
      </div>
      <div
        class="tw-truncate"
        title={`${props.driverlocation.vehicleZipcode} ${props.driverlocation.vehicleMunicipiality}`}
      >
        {props.driverlocation.vehicleMunicipiality}
      </div>
      <div class="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-whitespace-nowrap">
        <div>{formattedSpeed()}</div>
        <div class="tw-text-muted-foreground">{formattedRelativeTime()}</div>
      </div>
    </div>
  );
};
