import { LoginForm, ct, lastUsedEmail } from "@mooovex/components";
import { signInWithPassword } from "@mooovex/supabase-client";
import { useNavigate, useSearchParams } from "@solidjs/router";
import { Component, createEffect } from "solid-js";

const LoginPage: Component = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams<{ confirmEmail?: string; accountMarkedForDeletion?: string }>();

  createEffect(() => {
    if (searchParams.confirmEmail) {
      alert(ct.account.created.message(searchParams.confirmEmail));
    }

    if (searchParams.accountMarkedForDeletion) {
      alert(ct.account.delete.confirmation.message());
    }
  });

  return (
    <LoginForm
      onSubmit={async (credentials) => {
        await signInWithPassword(credentials);
        navigate("/");
      }}
      registerHref="/register"
      resetPasswordHref="/resetPassword"
      email={searchParams.confirmEmail ?? lastUsedEmail()}
    />
  );
};

export default LoginPage;
