import { Tables } from "@mooovex/supabase";
import { ReactiveMap } from "@solid-primitives/map";
import { RealtimeChannel } from "@supabase/supabase-js";
import { batch } from "solid-js";
import {
  fetchStaffById,
  fetchStaffByIdBatch,
  fetchVehicleById,
  fetchVehicleByIdBatch,
  myStaff,
  staffCache,
  supabase,
  vehiclesCache,
} from ".";

export const liveLocationsCache = new ReactiveMap<string, Tables<"live_locations">>();

let liveLocationsSubscription: RealtimeChannel | undefined = undefined;

export async function fetchLiveLocationsOfTransportcompany(transportcompany_id: number) {
  const is_admin = !!myStaff()?.is_admin;

  const queryBuilder = supabase.from("live_locations").select();

  if (!is_admin) {
    const group_id = myStaff()?.group_id ?? undefined;
    if (group_id) {
      void queryBuilder.eq("group_id", group_id);
    } else {
      void queryBuilder.eq("current_transportcompany_id", transportcompany_id);
    }
  }

  const { data, error } = await queryBuilder;
  if (error) throw error;

  batch(() => {
    for (const liveLocation of data) {
      liveLocationsCache.set(liveLocation.driver_id, liveLocation);
    }
  });

  await batch(async () => {
    await fetchStaffByIdBatch(data.map((l) => l.driver_id));
    await fetchVehicleByIdBatch(data.filter((l) => l.current_vehicle_id !== null).map((l) => l.current_vehicle_id!));
  });

  return data;
}

export async function subscribeToLiveLocations(transportcompany_id: number) {
  if (!liveLocationsSubscription) {
    await fetchLiveLocationsOfTransportcompany(transportcompany_id);
    const group_id = myStaff()?.group_id ?? undefined;
    const is_admin = !!myStaff()?.is_admin;
    liveLocationsSubscription = supabase
      .channel(`live_locations`)
      .on<Tables<"live_locations">>(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "live_locations",
          filter: is_admin
            ? undefined
            : group_id !== undefined
              ? `group_id=eq.${group_id}`
              : `current_transportcompany_id=eq.${transportcompany_id}`,
        },
        async (payload) => {
          if (payload.eventType === "DELETE") {
            liveLocationsCache.delete(payload.old.driver_id!);
          } else {
            liveLocationsCache.set(payload.new.driver_id, payload.new);

            // if staff is not known, fetch them
            if (!staffCache.has(payload.new.driver_id)) {
              await fetchStaffById(payload.new.driver_id);
            }

            // if vehicle is not known, fetch it
            if (payload.new.current_vehicle_id && !vehiclesCache.has(payload.new.current_vehicle_id)) {
              await fetchVehicleById(payload.new.current_vehicle_id);
            }
          }
        }
      )
      .subscribe();

    console.log("sub live_locations");
  }
}

export async function unsubscribeFromLiveLocations(clearCache = false) {
  console.log("unsub live_locations");
  if (liveLocationsSubscription) {
    if (clearCache) {
      liveLocationsCache.clear();
    }
    await supabase.removeChannel(liveLocationsSubscription);
    liveLocationsSubscription = undefined;
  }
}
