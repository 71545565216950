import { RegisterForm, currentLocale } from "@mooovex/components";
import { signUp } from "@mooovex/supabase-client";
import { useNavigate, useSearchParams } from "@solidjs/router";
import { Component } from "solid-js";

const RegisterPage: Component = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams<{ email?: string }>();

  return (
    <RegisterForm
      onSubmit={async (userData) => {
        if (
          await signUp(userData.email, userData.password, {
            first_name: userData.name,
            last_name: userData.surname,
            phone: userData.phone,
            locale: currentLocale(),
          })
        ) {
          navigate(`/login?confirmEmail=${userData.email}`);
        } else {
          throw new Error("Registration failed");
        }
      }}
      loginHref="/login"
      email={searchParams.email}
    />
  );
};

export default RegisterPage;
