// import * as API from "@mooovex/api-schema";
import { MyTimestamp } from "@mooovex/helpers";
import { ApiClientError, client } from "./client";

export async function compute(options: {
  routeId: string | number;
  passengerCount: number;
  timestamp: MyTimestamp;
  discount?: number;
  transportcompanyId?: number;
  // vehicleId?: string | number;
  // extras?: API.price.ChosenVehicleExtra[];
}) {
  const response = await client.v1.price[":onlinebooking_config_id"].$post({
    param: {
      onlinebooking_config_id: import.meta.env.VITE_ONLINEBOOKING_CONFIG_ID,
    },
    json: {
      route_id: options.routeId.toString(),
      passengerCount: options.passengerCount,
      when: {
        date: options.timestamp.getMyDate().toJSON(),
        time: options.timestamp.getMyTime().toJSON(),
      },
      discount: options.discount,
      transportcompanyId: options.transportcompanyId,
      // extras: options.extras,
      // vehicle_id: options.vehicleId?.toString(),
    },
  });

  if (!response.ok) {
    throw new ApiClientError("Price Compute failed", response);
  }

  return await response.json();
}
