import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-zod";
import { markAccountForDeletion, signOut } from "@mooovex/supabase-client";
import { useNavigate } from "@solidjs/router";
import { Component, Show, createSignal, useContext } from "solid-js";
import { z } from "zod";
import { ct } from "./i18n";
import { ModalContext } from "./Modal";

export const DeleteAccountForm: Component = () => {
  const modalContext = useContext(ModalContext);

  const navigate = useNavigate();
  const schema = z.object({ password: z.string().min(1) });
  const [status, setStatus] = createSignal<"ready" | "error" | "loading">("ready");

  const { form, errors, isValid, resetField, reset } = createForm<z.infer<typeof schema>>({
    extend: [validator({ schema })],
    onSubmit: async (values) => {
      setStatus("loading");
      try {
        await markAccountForDeletion(values.password);
        setStatus("ready");
        await signOut();
        navigate("/login?accountMarkedForDeletion=true");
      } catch (error) {
        console.log(error);
        setStatus("error");
        resetField("password");
      }
    },
  });

  if (modalContext) {
    const [Modal, { onHide }] = modalContext;
    onHide(reset);
  }

  return (
    <form ref={form}>
      <div class="mb-1">{ct.account.delete.warning()}</div>
      <div class="form-floating mb-1">
        <input
          name="password"
          type="password"
          class="form-control"
          classList={{ "is-invalid": !!errors().password?.length }}
          placeholder={ct.account.password()}
        />
        <label for="password" class="form-label">
          {ct.account.password()}
        </label>
        <div class="text-danger" classList={{ invisible: !errors().password?.length }}>
          {errors().password?.[0] ?? "-"}
        </div>
      </div>
      <Show when={status() === "error"}>
        <div class="alert alert-danger">{ct.account.delete.error()}</div>
      </Show>
      <button
        type="submit"
        class="btn btn-lg btn-danger w-100 d-flex justify-content-center align-items-center gap-2"
        disabled={!isValid()}
      >
        <Show when={status() === "loading"}>
          <div class="spinner-border spinner-border-sm" />
        </Show>
        {ct.account.delete._()}
      </button>
    </form>
  );
};
