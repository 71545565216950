import { DropdownMenuSubTriggerProps, DropdownMenuTriggerProps } from "@kobalte/core/dropdown-menu";
import * as mooovexApiClient from "@mooovex/api-client";
import { Shared } from "@mooovex/api-schema";
import { ct, IdentifierBadge } from "@mooovex/components";
import { Button } from "@mooovex/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuGroupLabel,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@mooovex/components/ui/dropdown-menu";
import { makePersisted } from "@solid-primitives/storage";
import { createQuery } from "@tanstack/solid-query";
import ArrowUpDownIcon from "lucide-solid/icons/arrow-up-down";
import CoinsIcon from "lucide-solid/icons/coins";
import CreditCardIcon from "lucide-solid/icons/credit-card";
import EllipsisIcon from "lucide-solid/icons/ellipsis";
import ReceiptTextIcon from "lucide-solid/icons/receipt-text";
import RotateCcwIcon from "lucide-solid/icons/rotate-ccw";
import Trash2Icon from "lucide-solid/icons/trash-2";
import { Component, For, ParentProps, Show, Suspense } from "solid-js";
import { createStore } from "solid-js/store";
import { Dynamic } from "solid-js/web";
import { selectedTransportcompanyId } from "../services/state/transportcompanies.state";
import { NavbarActions } from "./Navbar.c";

const PAYMENT_METHOD_ICONS: Record<Shared.PaymentMethod, Component> = {
  card: CreditCardIcon,
  cash: CoinsIcon,
  invoice: ReceiptTextIcon,
};

export function TransferList() {
  const [order, setOrder] = makePersisted(
    createStore<{ date: "asc" | "desc"; time: "asc" | "desc" }>({ date: "desc", time: "asc" }),
    { name: "mooovex:transferListOrder" }
  );

  const transfersQuery = createQuery(() => ({
    queryKey: ["transfersOfTransportcompany", selectedTransportcompanyId(), { date: order.date, time: order.time }],
    queryFn: async () => {
      const response = await mooovexApiClient.bearerClient.v2.transfers.ofTransportcompany.$get({
        query: {
          asTransportcompanyId: selectedTransportcompanyId()!.toString(),
          dateOrder: order.date,
          timeOrder: order.time,
        },
      });

      if (response.status !== 200) {
        throw new Error("Failed to fetch transfers", { cause: response });
      }

      return await response.json();
    },
    staleTime: 60000,
    select: (data) => data.dates,
  }));

  return (
    <>
      <NavbarActions>
        <DropdownMenu>
          <DropdownMenuTrigger
            as={(props: DropdownMenuSubTriggerProps) => (
              <Button size="icon" variant="outline" {...props}>
                <ArrowUpDownIcon />
              </Button>
            )}
          />
          <DropdownMenuContent>
            <DropdownMenuGroup>
              <DropdownMenuGroupLabel>{ct.common.date()}</DropdownMenuGroupLabel>
              <DropdownMenuRadioGroup
                value={order.date}
                onChange={(value) => setOrder("date", value as "asc" | "desc")}
              >
                <DropdownMenuRadioItem value="asc">{ct.order.oldFirst()}</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="desc">{ct.order.newFirst()}</DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuGroupLabel>{ct.common.time()}</DropdownMenuGroupLabel>
              <DropdownMenuRadioGroup
                value={order.time}
                onChange={(value) => setOrder("time", value as "asc" | "desc")}
              >
                <DropdownMenuRadioItem value="asc">{ct.order.oldFirst()}</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="desc">{ct.order.newFirst()}</DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <Button size="icon" variant="outline" onclick={() => transfersQuery.refetch()}>
          <RotateCcwIcon />
        </Button>
      </NavbarActions>
      <div class="tw-h-full tw-grow tw-overflow-auto">
        <Suspense
          fallback={<div class="tw-flex tw-h-full tw-items-center tw-justify-center">{ct.common.loading()}...</div>}
        >
          <Show
            when={transfersQuery.data?.length}
            fallback={<div class="tw-flex tw-h-full tw-items-center tw-justify-center">{ct.common.noResults()}</div>}
          >
            <Grid>
              <For each={transfersQuery.data}>
                {(day, i) => (
                  <>
                    <Day
                      date={day.date}
                      priceSum={day.priceSum}
                      priceSumByPaymentMethod={day.priceSumByPaymentMethod}
                      showGap={i() !== 0}
                    >
                      <For each={day.drivers}>
                        {(driver, i) => (
                          <>
                            <Driver
                              name={driver.name}
                              priceSum={driver.priceSum}
                              priceSumByPaymentMethod={driver.priceSumByPaymentMethod}
                              showPriceSums={day.drivers.length > 1}
                              showGap={i() !== 0}
                            >
                              <For each={driver.transfers}>
                                {(transfer) => (
                                  <Row
                                    vehicleIdentifier={transfer.vehicleIdentifier}
                                    startTime={transfer.startTime}
                                    endTime={transfer.endTime}
                                    distance={transfer.distance / 1000}
                                    pax={transfer.pax}
                                    price={transfer.price}
                                    paymentMethod={transfer.paymentMethod}
                                  />
                                )}
                              </For>
                            </Driver>
                          </>
                        )}
                      </For>
                    </Day>
                  </>
                )}
              </For>
            </Grid>
          </Show>
        </Suspense>
      </div>
    </>
  );
  function Grid(props: ParentProps) {
    return (
      <div class="tw-grid tw-grid-cols-[30px_repeat(4,minmax(60px,1fr))] tw-items-center tw-overflow-clip tw-text-nowrap tw-px-1">
        {props.children}
      </div>
    );
  }
  function Day(
    props: ParentProps<{
      date: string;
      priceSum: number;
      priceSumByPaymentMethod: Array<{ paymentMethod: Shared.PaymentMethod; priceSum: number }>;
      showGap: boolean;
    }>
  ) {
    return (
      <>
        <div
          class="tw-sticky tw-top-0 tw-col-span-full -tw-mx-1 tw-flex tw-h-12 tw-items-center tw-justify-between tw-gap-1 tw-bg-neutral-300 tw-px-1"
          classList={{ "tw-mt-12": props.showGap }}
        >
          <div class="tw-grow tw-text-center tw-text-xl tw-font-bold">{ct.formatters.date(new Date(props.date))}</div>
          <div class="tw-flex tw-gap-1">
            <DropdownMenu>
              <DropdownMenuTrigger
                as={(props: DropdownMenuTriggerProps) => (
                  <Button {...props} variant="outline" size="icon">
                    <EllipsisIcon />
                  </Button>
                )}
              />
              <DropdownMenuContent>
                <DropdownMenuItem
                  class="tw-gap-1 tw-text-destructive"
                  onClick={async () => {
                    if (await confirmDeleteTransfersOfDate(props.date)) {
                      await deleteTransfersOfDate(props.date);
                    }
                  }}
                >
                  <Trash2Icon />
                  <span>{ct.common.delete()}</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        {props.children}
        <div class="tw-col-span-full -tw-mx-1 tw-flex tw-h-12 tw-items-center tw-justify-center tw-bg-neutral-300">
          <For each={props.priceSumByPaymentMethod}>
            {({ paymentMethod, priceSum }) => (
              <div class="tw-flex tw-h-full tw-grow tw-items-center tw-justify-center tw-gap-1 tw-border-y [&:not(:last-child)]:tw-border-r">
                <Dynamic component={PAYMENT_METHOD_ICONS[paymentMethod]} />
                <span>{ct.formatters.euros(priceSum)}</span>
              </div>
            )}
          </For>
        </div>
        <div class="tw-col-span-full -tw-mx-1 tw-flex tw-h-12 tw-items-center tw-justify-end tw-gap-1 tw-bg-neutral-300 tw-px-1 tw-font-bold tw-shadow">
          <span>{ct.transferList.dailyTotal()}:</span>
          <span>{ct.formatters.euros(props.priceSum)}</span>
        </div>
      </>
    );
  }

  function Driver(
    props: ParentProps<{
      name: string;
      priceSum: number;
      priceSumByPaymentMethod: Array<{ paymentMethod: Shared.PaymentMethod; priceSum: number }>;
      showPriceSums: boolean;
      showGap: boolean;
    }>
  ) {
    return (
      <>
        <div
          class="tw-sticky tw-top-12 tw-col-span-full -tw-mx-1 tw-flex tw-h-12 tw-items-center tw-justify-between tw-gap-1 tw-bg-neutral-100 tw-px-1"
          classList={{ "tw-mt-6": props.showGap }}
        >
          <div class="tw-text-xl">{props.name}</div>
          <div class="tw-flex tw-gap-1">{/* Driver Actions */}</div>
        </div>
        {props.children}
        <Show when={props.showPriceSums}>
          <div class="tw-col-span-full -tw-mx-1 tw-flex tw-h-12 tw-items-center tw-justify-center tw-bg-neutral-100">
            <For each={props.priceSumByPaymentMethod}>
              {({ paymentMethod, priceSum }) => (
                <div class="tw-flex tw-h-full tw-grow tw-items-center tw-justify-center tw-gap-1 tw-border-y [&:not(:last-child)]:tw-border-r">
                  <Dynamic component={PAYMENT_METHOD_ICONS[paymentMethod]} />
                  <span>{ct.formatters.euros(priceSum)}</span>
                </div>
              )}
            </For>
          </div>
          <div class="tw-col-span-full -tw-mx-1 tw-flex tw-h-12 tw-items-center tw-justify-end tw-gap-1 tw-bg-neutral-100 tw-px-1 tw-font-bold tw-shadow">
            <span>{ct.transferList.driversDailyTotal()}:</span>
            <span>{ct.formatters.euros(props.priceSum)}</span>
          </div>
        </Show>
      </>
    );
  }

  function Row(props: {
    vehicleIdentifier: string;
    startTime: string;
    endTime: string;
    distance: number;
    pax: number;
    price: number;
    paymentMethod: Shared.PaymentMethod;
  }) {
    return (
      <>
        {/* Defined row height on first cell */}
        <div class="tw-flex tw-h-12 tw-items-center">
          <IdentifierBadge>{props.vehicleIdentifier}</IdentifierBadge>
        </div>
        <div class="tw-text-center">
          {props.startTime} - {props.endTime}
        </div>
        <div class="tw-text-end">{ct.formatters.kilometers(props.distance)}</div>
        <div class="tw-text-center">{props.pax} pax</div>
        <div class="tw-flex tw-justify-end tw-gap-1">
          <Dynamic component={PAYMENT_METHOD_ICONS[props.paymentMethod]} />
          <span>{ct.formatters.euros(props.price)}</span>
        </div>
      </>
    );
  }

  async function deleteTransfersOfDate(date: string) {
    const response = await mooovexApiClient.bearerClient.v2.transfers.date.$delete({
      query: {
        asTransportcompanyId: selectedTransportcompanyId()!.toString(),
        date,
      },
    });

    if (response.status !== 204) {
      alert(ct.transferList.failedToDeleteTransfersOfDate(new Date(date)));
    }

    transfersQuery.refetch();
  }
}

function confirmDeleteTransfersOfDate(date: string) {
  return confirm(ct.transferList.confirmDeleteTransfersOfDate.message(new Date(date)));
}
