import { cva, VariantProps } from "class-variance-authority";
import { ParentProps, Show, splitProps } from "solid-js";
import { cn } from "../cn";

const identifierBadgeVariants = cva(
  "tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-text-center tw-leading-none",
  {
    variants: {
      size: {
        sm: "tw-size-6 tw-text-xs",
        default: "tw-size-7 tw-text-sm",
        lg: "tw-size-8 tw-text-base",
      },
      status: {
        available: "tw-bg-status-available tw-text-status-available-foreground",
        empty: "tw-bg-status-empty tw-text-status-empty-foreground",
        occupied: "tw-bg-status-occupied tw-text-status-occupied-foreground",
        invisible: "tw-bg-status-invisible tw-text-status-invisible-foreground",
        outofservice: "tw-bg-status-outofservice tw-text-status-outofservice-foreground",
      },
    },
    defaultVariants: {
      status: "outofservice",
      size: "default",
    },
  }
);

type Props = ParentProps<{
  class?: string;
}> &
  VariantProps<typeof identifierBadgeVariants>;

export const IdentifierBadge = (props: Props) => {
  const [local, rest] = splitProps(props, ["class", "size", "status"]);
  return (
    <div class={cn(identifierBadgeVariants({ size: local.size, status: local.status }), local.class)} {...rest}>
      <Show when={props.children} fallback={<div class="spinner-border spinner-border-sm" />}>
        {props.children}
      </Show>
    </div>
  );
};
