import { DropdownMenuTriggerProps } from "@kobalte/core/dropdown-menu";
import { Shared } from "@mooovex/api-schema";
import { setLocaleRequest } from "@mooovex/supabase-client";
import LanguagesIcon from "lucide-solid/icons/languages";
import { Component, For, createSignal } from "solid-js";
import { SUPPORTED_LOCALES, currentLocale, setLocale } from "./i18n";
import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";

type Props = {
  onError?: (error: unknown) => void;
};

export const LocaleSelectorDropdown: Component<Props> = (props) => {
  const [loading, setLoading] = createSignal(false);

  async function changeLocale(locale: Shared.Locale) {
    setLoading(true);
    try {
      const updatedLocale = await setLocaleRequest(locale);
      setLocale(updatedLocale);
    } catch (error) {
      props.onError?.(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger
          as={(triggerProps: DropdownMenuTriggerProps) => (
            <Button {...triggerProps} variant="outline">
              <LanguagesIcon class="tw-mr-1" size={16} />
              {currentLocale().toUpperCase()}
            </Button>
          )}
        />
        <DropdownMenuContent>
          <DropdownMenuRadioGroup value={currentLocale()} onChange={(value) => changeLocale(value as Shared.Locale)}>
            <For each={SUPPORTED_LOCALES}>
              {(locale) => (
                <DropdownMenuRadioItem value={locale} closeOnSelect>
                  {locale.toUpperCase()}
                </DropdownMenuRadioItem>
              )}
            </For>
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};
