import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-zod";
import logoUrl from "@mooovex/assets/images/mooovex_logo.svg";
import { ct } from "@mooovex/components";
import { Button } from "@mooovex/components/ui/button";
import { Card, CardContent, CardHeader } from "@mooovex/components/ui/card";
import { supabase } from "@mooovex/supabase-client";
import { useNavigate, useSearchParams } from "@solidjs/router";
import LoaderCircleIcon from "lucide-solid/icons/loader-circle";
import { Component, Show, createSignal } from "solid-js";
import { z } from "zod";
type Props = {};

export const OTPPage: Component<Props> = (props) => {
  const [params] = useSearchParams<{ email: string }>();
  const [loadingStatus, setLoadingStatus] = createSignal<"ready" | "error" | "loading">("ready");
  const navigate = useNavigate();
  const schema = z.object({ otp: z.string().min(6).max(6) });
  const { form, errors, isValid } = createForm<z.infer<typeof schema>>({
    extend: [validator({ schema })],
    onSubmit: async ({ otp }) => {
      setLoadingStatus("loading");
      try {
        const { error } = await supabase.auth.verifyOtp({ type: "recovery", token: otp, email: params.email! });
        if (error) throw error;
        navigate("/user?changePassword=true");
        setLoadingStatus("ready");
      } catch (error) {
        setLoadingStatus("error");
      }
    },
  });

  return (
    <>
      <form ref={form} class="tw-flex tw-grow tw-items-center tw-justify-center tw-p-4">
        <Card class="tw-w-full tw-max-w-md tw-p-4">
          <CardHeader class="tw-flex tw-items-center tw-justify-center tw-pb-4">
            <img src={logoUrl} alt="logo" class="tw-h-auto tw-max-w-[150px]" />
          </CardHeader>
          <CardContent class="tw-flex tw-w-full tw-flex-col">
            <div class="form-floating mb-1">
              <input
                ref={(ref) => ref.focus()}
                name="otp"
                type="text"
                class="form-control"
                classList={{ "is-invalid": !!errors().otp?.length }}
                placeholder=""
              />
              <label for="otp" class="form-label">
                {ct.account.sixdigitcode()}
              </label>
              <div class="text-danger" classList={{ invisible: !errors().otp?.length }}>
                {errors().otp?.[0] ?? "-"}
              </div>
            </div>
            <Show when={loadingStatus() === "error"}>
              <div class="alert alert-danger">{ct["account.codeVerifyFailed"]()}</div>
            </Show>
            <Button size="lg" block type="submit" class="tw-w-full tw-gap-2" disabled={!isValid()}>
              <Show when={loadingStatus() === "loading"}>
                <LoaderCircleIcon class="tw-animate-spin" />
              </Show>
              {ct["common.continue"]()}
            </Button>
          </CardContent>
        </Card>
      </form>
    </>
  );
};
