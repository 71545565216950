import type { HonoRoutes } from "@mooovex/server";
import { ClientResponse, hc } from "hono/client";

export const client = hc<HonoRoutes>(import.meta.env.VITE_PRICING_SERVER_URL!, {
  headers: {
    Authorization: import.meta.env.VITE_PRICING_API_KEY,
  },
});

export const bearerClient = hc<HonoRoutes>(import.meta.env.VITE_PRICING_SERVER_URL!, {
  headers: (): Record<string, string> => {
    // https://github.com/supabase/supabase-js/blob/52ffdf79bf36b47a67f2bda6623ed3bf5590bd0a/src/SupabaseClient.ts#L86
    const authTokenName = `sb-${new URL(import.meta.env.VITE_SUPABASE_URL!).hostname.split(".")[0]}-auth-token`;
    const authToken = localStorage.getItem(authTokenName);
    if (authToken !== null) {
      return {
        Authorization: `Bearer ${JSON.parse(authToken).access_token}`,
      };
    }

    return {};
  },
});

export class ApiClientError<T> extends Error {
  public status: number;
  public statusText: string;
  constructor(message: string, response: ClientResponse<T>) {
    super(message, { cause: response });
    this.status = response.status;
    this.statusText = response.statusText;
  }
}
